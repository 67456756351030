import {
  Cell,
  SpreadSheetSettings,
  toEndOfMonth,
} from '@aksia-monorepo/shared-ui';
import { Stream } from '../stream/stream.model';
import { StreamSourceEnum } from '../../enums/enums';

export class StreamSpreadSheet {
  entityId: number;
  entityTypeId: number;
  entityStartDate: Date;
  values: Array<number> = [];
  isVisible: boolean = false;
  showHistory: boolean = false;
  showHistoryToggle: boolean = true;
  showCloseToggle: boolean = true;
  hasFullTrackRecord: boolean = false;
  settings: SpreadSheetSettings = null;

  streamToSpreadsheet(stream: Array<Stream>) {
    if (this.entityStartDate) {
      const dataAndRows = this.getBulkStream(
        stream,
        this.entityStartDate.getFullYear()
      );
      let ranges = this.getStreamRanges(
        this.entityStartDate,
        this.settings.columnTitles,
        dataAndRows[0],
        dataAndRows[2],
        dataAndRows[3]
      );
      this.values = ranges.data;
      this.settings.rowTitles = ranges.rowTitles;
      this.settings.cellStatus = ranges.cellStatus;
      this.settings.cellInfo = ranges.cellInfo;
    }
  }

  spreadSheetToStream(
    values: Array<number>,
    stream: Array<Stream>,
    selectedStream: Stream
  ): { new: Array<Stream>; modified: Array<Stream> } {
    let streamModified: Array<Stream> = [];
    let streamNew: Array<Stream> = [];
    values.forEach((value: number, i: number) => {
      const yearIndex = Math.floor(i / this.settings.columns);
      const currentYear = +this.settings.rowTitles[yearIndex];
      const currentMonth = i - yearIndex * this.settings.columns + 1;
      this.values[i] = value;

      const currentStreamPoint = stream.find(
        (x) =>
          x.asOf.getFullYear() === currentYear &&
          x.asOf.getMonth() === currentMonth - 1
      );
      if (!currentStreamPoint && value) {
        let newStreamPoint = new Stream(this.entityId, this.entityTypeId);
        newStreamPoint.asOf = new Date(currentYear, currentMonth, 0);
        newStreamPoint.value = value;
        newStreamPoint.source = selectedStream?.source;
        stream.push(newStreamPoint);
        streamNew.push(newStreamPoint);
      } else if (currentStreamPoint && currentStreamPoint.value != value) {
        currentStreamPoint.markedForDeletion = !value;
        currentStreamPoint.source = currentStreamPoint.markedForDeletion
          ? currentStreamPoint.source
          : selectedStream?.source;
        currentStreamPoint.value = value === null ? undefined : value;
        streamModified.push(currentStreamPoint);
      }
    });
    return { new: streamNew, modified: streamModified };
  }

  private getBulkStream(stream: Array<Stream>, startYear: number): any[] {
    // create the years array
    const sortedYears =
      stream?.length > 0
        ? stream
            ?.sort(
              (a: Stream, b: Stream) =>
                a?.asOf.getFullYear() - b?.asOf.getFullYear()
            )
            .map((x) => x?.asOf.getFullYear())
        : [new Date().getFullYear()];
    let maxYear = new Date().getFullYear();
    const minYear =
      !startYear || startYear == null || startYear > sortedYears[0]
        ? sortedYears[0]
        : startYear;
    const streamValues: number[] = [];
    const streamYears: number[] = [];
    const streamStatus: string[] = [];
    const streamInfo: Array<any> = [];

    while (minYear <= maxYear) {
      streamYears.push(maxYear);
      for (let month = 0; month < 12; month++) {
        let curPoint = stream?.find(
          (x) =>
            new Date(x?.asOf).getFullYear() == maxYear &&
            new Date(x?.asOf).getMonth() == month
        );
        streamValues.push(!curPoint ? null : curPoint.value);
        streamInfo.push({
          metaId: curPoint?.metaId,
          bgColorClass: curPoint?.bgColorFromClassification,
          data: toEndOfMonth(new Date(maxYear, month, 1)),
        });
      }
      maxYear--;
    }

    return [streamValues, streamYears, streamStatus, streamInfo];
  }

  private getStreamRanges(
    minDate: Date,
    columnTitles: Array<string>,
    data: Array<number> = null,
    streamStatus: Array<string>,
    streamInfo: Array<{ metaId: number; bgColorClass: string }>
  ): {
    data: Array<number>;
    rowTitles: Array<string>;
    cellStatus: Array<string>;
    cellInfo: Array<{ metaId: number; bgColorClass: string }>;
  } {
    let ranges = {
      data: [],
      rowTitles: [],
      cellStatus: [],
      cellInfo: [],
    };
    ranges.data, ranges.rowTitles, ranges.cellStatus, (ranges.cellInfo = []);

    let minYM =
      new Date(minDate).getFullYear() * 100 + new Date(minDate).getMonth();
    let maxYM = new Date().getFullYear() * 100 + new Date().getMonth() - 1;

    Array.from(
      {
        length: new Date().getFullYear() - new Date(minDate).getFullYear() + 1,
      },
      (_, i) => {
        ranges.rowTitles.push((new Date().getFullYear() - i).toString());
        Array.from(Array(12), (_, j) => {
          let currentDate = new Date(
            columnTitles[j % 12] +
              '-' +
              (new Date().getFullYear() - i).toString()
          );
          let date = currentDate.getFullYear() * 100 + currentDate.getMonth();
          if (date >= minYM && date <= maxYM) {
            ranges.cellStatus.push(streamStatus[i * 12 + j]);
            ranges.cellInfo.push(streamInfo[i * 12 + j]);
            if (data) {
              ranges.data.push(data[i * 12 + j]);
            }
          } else {
            if (data) {
              ranges.data.push(null);
            }
            ranges.cellStatus.push('disabled');
            ranges.cellInfo.push(null);
          }
        });
      }
    );
    return ranges;
  }

  getCellIndexByAsOf(asOf: string): number {
    let colIndex = this.settings.columnTitles.indexOf(
      asOf?.split('-')[0]?.trim()
    );
    let rowIndex = this.settings.rowTitles.indexOf(asOf?.split('-')[1]?.trim());
    return rowIndex * this.settings.columns + colIndex;
  }

  constructor(
    entityId: number,
    entityTypeId: number,
    entityStartDate: Date,
    columnTitles: Array<string>,
    rowTitles: Array<string>,
    disabledCells: Array<boolean>
  ) {
    this.entityId = entityId;
    this.entityTypeId = entityTypeId;
    this.entityStartDate = entityStartDate;
    this.settings = new SpreadSheetSettings(
      columnTitles,
      rowTitles,
      disabledCells
    );
    this.settings.selectedCell = new Cell(0, 0);
  }
}
