import { ModelState, SpreadSheetSettings, ValidationSummary } from '@aksia-monorepo/shared-ui';
import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ManagementCompany, Fund, Program, AUMSpreadSheet } from '../classes';
import { AUMeta } from '../classes/aum/aum.meta';
import { CompanyUtils } from '../classes/company/company.utils';
import { FundUtils } from '../classes/fund/fund.utils';
import { TakeUntilDestroy } from '../decorators/destroy.decorator';
import { EntityTypeEnum, LiquidityStructureEnum } from '../enums/enums';
import { LoadingService } from './loading.service';
import { PERIODIC_STREAMS } from './periodic-streams.service';
import { TaxonomyService } from './taxonomy.service';

interface IValidationResult {
  groupEntity: string,
  groupName: string,
  alias: string,
  errors: string
}

@Injectable({
  providedIn: 'root'
})
@TakeUntilDestroy
export class EntityService implements OnDestroy {
  protected componentDestroy: () => Observable<unknown>;
  
  protected endPointsResponsed = new BehaviorSubject<number>(0);
  public endPointsRequesting = 0;
  public validationSummary: ValidationSummary;
  public validationResults: Array<IValidationResult> = [];
  public validationResultMaps: {
    Company?: Array<IValidationResult>,
    Program?: Array<IValidationResult>,
    Fund?: Array<IValidationResult>,
    Class?: Array<IValidationResult>,
    AUM?: Array<IValidationResult>,
    ProgramAUM?: Array<IValidationResult>,
    netIRR?: Array<IValidationResult>,
    grossIRR?: Array<IValidationResult>,
    netMOIC?: Array<IValidationResult>,
    grossMOIC?: Array<IValidationResult>,
    netDPI?: Array<IValidationResult>,
    netRVPI?: Array<IValidationResult>,
    investedCapital?: Array<IValidationResult>
  } = {}
  public serverError: string = '';
  public hasError: boolean;
  
  private _localEntity: ManagementCompany | Fund;
  private readonly _entity = new BehaviorSubject<ManagementCompany | Fund>(undefined);
  readonly entity$ = this._entity.asObservable();

  //#region Getters/Setters

  public get localEntity(): ManagementCompany | Fund {
    return this._localEntity;
  }

  public set localEntity(value: ManagementCompany | Fund) {
    this._localEntity = value;
  }

  public get localFund(): Fund {
    return this._localEntity as Fund;
  }

  public set localFund(value: Fund) {
    this._localEntity = value;
  }

  public get localCompany(): ManagementCompany {
    return this._localEntity as ManagementCompany;
  }

  public set localCompany(value: ManagementCompany) {
    this._localEntity = value;
  }

  public get entity(): ManagementCompany | Fund {
    return this._entity.getValue();
  }

  public set entity(next: ManagementCompany | Fund) {
    this._entity.next(next);
  }

  public get fund(): Fund {
    return this._entity.getValue() as Fund;
  }

  public set fund(value: Fund) {
    this._entity.next(value);
  }

  public get company(): ManagementCompany {
    return this._entity.getValue() as ManagementCompany;
  }

  public set company(value: ManagementCompany) {
    this._entity.next(value);
  }

  //#endregion

  constructor(private loadingService: LoadingService, private title: Title, protected taxService: TaxonomyService) { 
    this.endPointsResponsed
    .pipe(
      takeUntil(this.componentDestroy())
    )
    .subscribe({
      next: (endPointsResponsed: number) => {
        if (endPointsResponsed && endPointsResponsed >= this.endPointsRequesting) {
          this.entity = this.localEntity;
          this.endPointsResponsed.next(0);
          this.title.setTitle(this.entity.name);
          this.loadingService.loading(false);
          fromEvent(document, 'click')
          .pipe( 
            take(1) 
          )
          .subscribe((event: MouseEvent) => {
            this.setAfterSaveStatus();
          })
        }
      }
    })
  }

  ngOnDestroy() {}

  public endPointResponsed(ctx?: EntityService) {
    (this ?? ctx).endPointsResponsed.next((this ?? ctx).endPointsResponsed.getValue() + 1);
  }

  protected validateAndPrepare(entityType: EntityTypeEnum = EntityTypeEnum.Fund) {
    this.setBeforeSaveStatus();
    this.updateGlobalStatus(entityType);
    
    if (entityType === EntityTypeEnum.Fund){
      this.localFund = FundUtils.copy(this.fund);
    }
    else {
      this.localCompany = CompanyUtils.copy(this.company);
    }
  }

  protected getValidationResults(groupEntity: string, summary?: ValidationSummary) {
    summary = summary ?? this.validationSummary;
    if (summary.groupEntity === groupEntity && summary.fields?.length > 0){
      summary.fields.forEach(field => {
        if (!this.validationResults.some( vr => vr.groupName === summary.groupName && vr.groupEntity === summary.groupEntity && vr.alias === field.alias && vr.errors === field.errors.join(", "))){
          this.validationResults.push({
            groupEntity: summary.groupEntity,
            groupName: summary.groupName,
            alias: field.alias,
            errors: field.errors?.join(", ")
          })  
        }
      });
    }
    else if (summary.children?.length > 0){
      summary.children.forEach( item => {
        this.getValidationResults(item.groupEntity,item);
      });
    }
  }

  private setBeforeSaveStatus() {
    this.hasError = false;
    this.serverError = '';
    this.endPointsRequesting = 0;
    this.endPointsResponsed.next(0);
    this.validationResults = [];
    this.validationSummary = this.entity.getValidationSummary();
    
    this.entity.state = (this.entity.state) ? ModelState.IsDirty : this.entity.state;
    if (this.entity.aumMeta){
      this.entity.aumMeta.state = (this.entity.aumMeta.state) ? ModelState.IsDirty : this.entity.aumMeta.state;
    }

    if (this.entity instanceof Fund){
      this.fund.investmentProgram.state = (this.fund.investmentProgram.state) ? ModelState.IsDirty : this.fund.investmentProgram.state;
      this.fund.shareClasses.forEach( sc => sc.state = (sc.state) ? ModelState.IsDirty : sc.state);
      if (this.fund.liquidityStructure === LiquidityStructureEnum.ClosedEnd){
        Object.values(PERIODIC_STREAMS).forEach(streamName => {
          if (this.fund[`${streamName}Meta`]){
            this.fund[`${streamName}Meta`].state = this.fund[`${streamName}Meta`].state ? ModelState.IsDirty : this.fund[`${streamName}Meta`].state;
          }
        });
      }
    }
    else {
      this.company.investmentPrograms.forEach( pr => {
        pr.state = (pr.state) ? ModelState.IsDirty : pr.state;
        pr.aumMeta.state = (pr.aumMeta.state) ? ModelState.IsDirty : pr.aumMeta.state;
      });
      this.company.programState = (this.company.programState) ? ModelState.IsDirty : this.company.programState;
      this.company.programAUMState = (this.company.programAUMState) ? ModelState.IsDirty : this.company.programAUMState;
    }
  }

  private setAfterSaveStatus() {
    this.entity.state = (this.entity.state === ModelState.Saved) ? ModelState.Ready : this.entity.state;
    if (this.entity.aumMeta) {
      this.entity.aumMeta.state = (this.entity.aumMeta.state === ModelState.Saved) ? ModelState.Ready : this.entity.aumMeta.state;
    }

    if (this.entity instanceof Fund){
      this.fund.shareClasses.forEach( sc => sc.state = (sc.state === ModelState.Saved) ? ModelState.Ready : sc.state);
      
      if (this.fund.liquidityStructure === LiquidityStructureEnum.ClosedEnd){
        Object.values(PERIODIC_STREAMS).forEach(streamName => {
          if (this.fund[`${streamName}Meta`]){
            this.fund[`${streamName}Meta`].state = (this.fund[`${streamName}Meta`].state === ModelState.Saved) ? ModelState.Ready : this.fund[`${streamName}Meta`].state;
          }
        });
      }
      else {
        if (this.fund.publicreturnsMeta){
          this.fund.publicreturnsMeta.state = (this.fund.publicreturnsMeta.state === ModelState.Saved) ? ModelState.Ready : this.fund.publicreturnsMeta.state;
        }
      }
    }
    else {
      this.company.investmentPrograms?.forEach( pr => {
        pr.state = (pr.state === ModelState.Saved) ? ModelState.Ready : pr.state;
        if (pr.aumMeta){
          pr.aumMeta.state = (pr.aumMeta.state === ModelState.Saved) ? ModelState.Ready : pr.aumMeta.state;
        }
      });
      this.company.programState = (this.company.programState === ModelState.Saved) ? ModelState.Ready : this.company.programState;
      this.company.programAUMState = (this.company.programAUMState === ModelState.Saved) ? ModelState.Ready : this.company.programAUMState;
    }
  }

  private updateGlobalStatus(entityType){
    if (this.entity.aumMeta){
      this.getValidationResults(this.entity.aumMeta.classType);
      this.validationResultMaps.AUM = this.validationResults.filter( vr => vr.groupEntity === this.entity.aumMeta.classType);
    }
    
    if (entityType === EntityTypeEnum.Fund) {
      if (this.entity.aumMeta){
        this.fund.aumMeta.state = this.validationResultMaps.AUM?.length ? ModelState.HasError : this.fund.aumMeta.state;
      }

      if (this.fund.liquidityStructure === LiquidityStructureEnum.ClosedEnd){
        Object.values(PERIODIC_STREAMS).forEach(streamName => {
          if (this.fund[`${streamName}Meta`]){
            this.getValidationResults(this.fund[`${streamName}Meta`].classType);
            this.validationResultMaps[`${streamName}`] = this.validationResults.filter( vr => vr.groupEntity === this.fund[`${streamName}Meta`].classType);
            this.fund[`${streamName}Meta`].state = this.validationResultMaps[streamName]?.length ? ModelState.HasError : this.fund[`${streamName}Meta`].state;
          }
        });
      }
      
      this.getValidationResults(this.fund.classType);
      this.validationResultMaps.Fund = this.validationResults.filter( vr => vr.groupEntity === this.fund.classType);
      this.getValidationResults(this.fund.shareClasses[0].classType);
      this.validationResultMaps.Class = this.validationResults.filter( vr => vr.groupEntity === this.fund.shareClasses[0].classType);
      
      this.fund.state = this.validationResultMaps.Fund?.length ? ModelState.HasError : this.fund.state;
      this.fund.shareClasses.forEach( sc => { 
        sc.state = this.validationResultMaps.Class.map( vsc => vsc.groupName).includes(sc.className) ? ModelState.HasError : (sc.state ?? ModelState.Ready)
      });
      
    }
    else {
      if (this.entity.aumMeta) {
        this.company.aumMeta.state = this.validationResultMaps.AUM?.length ? ModelState.HasError : this.company.aumMeta.state;
      }

      this.getValidationResults(this.company.classType);
      this.validationResultMaps.Company = this.validationResults.filter( vr => vr.groupEntity === this.company.classType);

      if (this.company.investmentPrograms?.length > 0){
        this.getValidationResults(this.company.investmentPrograms[0].classType);
        this.validationResultMaps.Program = this.validationResults.filter( vr => vr.groupEntity === this.company.investmentPrograms[0].classType);
        this.getValidationResults(this.company.investmentPrograms[0]?.aumMeta?.classType);
        this.validationResultMaps.ProgramAUM = this.validationResults.filter( vr => vr.groupEntity === this.company.investmentPrograms[0].aumMeta.classType);
      }

      this.company.state = this.validationResultMaps.Company?.length ? ModelState.HasError : this.company.state;
      this.company.investmentPrograms.forEach( ip => { 
        ip.state = this.validationResultMaps.Program.map( vsc => vsc.groupName).includes(ip.name) ? ModelState.HasError : (ip.state ?? ModelState.Ready)
        this.company.programState = this.company.programState !== ModelState.HasError && this.company.programState !== ModelState.IsDirty ? ip.state : this.company.programState;
      });
      let programAUMStates = this.company.investmentPrograms.map( ip => ip.aumMeta?.state);
      this.company.programAUMState = this.validationResultMaps.ProgramAUM.some( vsc => vsc.errors?.length > 0) ? ModelState.HasError : programAUMStates.includes(ModelState.IsDirty) ? ModelState.IsDirty : ModelState.Ready  //programAUMStates.includes(ModelState.HasError) ? ModelState.HasError : (programAUMStates.includes(ModelState.IsDirty) ? ModelState.IsDirty : ModelState.Ready);  
    }
  }
}
