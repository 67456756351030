export const environment = {
  production: true,
  version: '2.6.1',
  maxBaseUrl: 'https://max-staging.aksia.com',
  originBaseUrl: 'https://origin-staging.aksia.com',
  maxUrl:
    'https://max-staging.aksia.com/Default.aspx?redirectUrl=https://o2-staging.aksia.com/',
  baseUrl: 'https://services-staging.aksia.com/qa/base',
  aiUrl: 'https://app-aiapp-test.azurewebsites.net', //'https://ai-api-dev.aksia.com/api',
  environmentVariable: 'staging',
  o2Url: 'https://o2-staging.aksia.com/',
  storageType: 3, // 1: Database, 2: Local Storage, 3: Both
  title: 'O2',
};
