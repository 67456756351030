import {
  ApiMicroService,
  Environment,
  ENVIRONMENT,
} from '@aksia-monorepo/shared-ui';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Stream } from '../classes/stream/stream.model';
import {
  StreamSourceEnum,
  StreamPeriodicity,
  EntityTypeEnum,
  DataProviderEnum,
  PublicReturnFeeEnum,
  PublicReturnGeneralClassificationEnum,
} from '../enums/enums';
import { IErrorResult } from '../interfaces/system.interface';

export const PERIODIC_STREAMS = {
  PUBLICRETURNS: 'publicreturns',
  NETIRR: 'netIRR',
  GROSSIRR: 'grossIRR',
  NETMOIC: 'netMOIC',
  GROSSMOIC: 'grossMOIC',
  NETDPI: 'netDPI',
  NETRVPI: 'netRVPI',
  INVESTED_CAPITAL: 'investedCapital',
};

export const PERIODIC_STREAMS_DISPLAY = {
  publicreturns: 'Public Returns',
  netIRR: 'Net IRR',
  grossIRR: 'Gross IRR',
  netMOIC: 'Net TVPI',
  grossMOIC: 'Gross TVPI',
  netDPI: 'Net DPI',
  netRVPI: 'Net RVPI',
  investedCapital: 'Invested Capital',
};

export type PeriodicStreamType =
  typeof PERIODIC_STREAMS[keyof typeof PERIODIC_STREAMS];

export interface IPeriodicStreamPoint {
  asOf: string;
  value: number;
  source: StreamSourceEnum;
}

export interface IPublicReturnStreamPoint extends IPeriodicStreamPoint {
  feeType: PublicReturnFeeEnum;
  classification: PublicReturnGeneralClassificationEnum;
  isPublic: boolean;
}

export interface IPeriodicStreamMinMaxPoint {
  fundId: number;
  min: IPeriodicStreamPoint;
  max: IPeriodicStreamPoint;
  periodicity: StreamPeriodicity;
}

export interface IPeriodicStream {
  fundId: number;
  periodicity: StreamPeriodicity;
  datapoints: Array<IPeriodicStreamPoint>;
}

export interface IPeriodicStreamMinMax {
  netirr: IPeriodicStreamMinMaxPoint;
  grossirr: IPeriodicStreamMinMaxPoint;
  netmoic: IPeriodicStreamMinMaxPoint;
  grossmoic: IPeriodicStreamMinMaxPoint;
  netdpi: IPeriodicStreamMinMaxPoint;
  netrvpi: IPeriodicStreamMinMaxPoint;
  investedcapital: IPeriodicStreamMinMaxPoint;
}

export interface IPeriodicStreamAsOf {
  netirr: IPeriodicStreamPoint;
  grossirr: IPeriodicStreamPoint;
  netmoic: IPeriodicStreamPoint;
  grossmoic: IPeriodicStreamPoint;
  netdpi: IPeriodicStreamPoint;
  netrvpi: IPeriodicStreamPoint;
  investedcapital: IPeriodicStreamPoint;
}

@Injectable({
  providedIn: 'root',
})
export class PeriodicStreamsService {
  constructor(
    private api: ApiMicroService,
    @Inject(ENVIRONMENT) private env: Environment
  ) {}

  public getSpecificStream(
    entityId: number,
    periodicStreamType: PeriodicStreamType
  ): Observable<IPeriodicStream | null> {
    return this.api.get(
      `periodicdata/returnprofile/${periodicStreamType}/${entityId}`
    );
  }

  public getAllStreamMinMaxPoints(
    entityId: number
  ): Observable<IPeriodicStreamMinMax | null> {
    return this.api.get(
      `periodicdata/returnprofile/allreturnprofilestreams/${entityId}/stream/asofrange`
    );
  }

  public getAllStreamAsOfPoints(
    entityId: number,
    asOf: string
  ): Observable<IPeriodicStreamAsOf | null> {
    return this.api.get(
      `periodicdata/returnprofile/allreturnprofilestreams/${entityId}/stream/datapoint?asOf=${asOf}`
    );
  }

  public getHistoricalStream(
    entityId: number,
    periodicStreamType: PeriodicStreamType,
    entityTypeId?: EntityTypeEnum
  ): Observable<IPeriodicStream | null> {
    let apiStreamURI =
      periodicStreamType === PERIODIC_STREAMS.PUBLICRETURNS
        ? `publicreturns/actual/${DataProviderEnum.Ostrako_O2}@${entityId}_${entityTypeId}/stream/rawwithytd`
        : `returnprofile/${periodicStreamType}/${entityId}`;
    return this.api.get(`periodicdata/${apiStreamURI}`);
  }

  public updateStream(
    entityId: number,
    stream: Array<Stream>,
    periodicStreamType: PeriodicStreamType,
    periodicity: StreamPeriodicity = StreamPeriodicity.Monthly
  ): Observable<Array<Stream | IErrorResult>> {
    const streamPlain =
      periodicStreamType === PERIODIC_STREAMS.PUBLICRETURNS
        ? stream.map((streamPoint) => {
            streamPoint.value = !streamPoint.markedForDeletion
              ? streamPoint.value / 100
              : 0;
            return streamPoint.toPlain();
          })
        : stream.map((streamPoint) => streamPoint.toPlain());
    let apiStreamURI =
      periodicStreamType === PERIODIC_STREAMS.PUBLICRETURNS
        ? `publicreturns/actual/${entityId}/stream/edit`
        : `returnprofile/${periodicStreamType}/${entityId}/stream/edit?periodicity=${periodicity}`;
    return this.api.put(`periodicdata/${apiStreamURI}`, streamPlain);
  }

  public uploadStream(
    entityId,
    investmentProgramId,
    payload
  ): Observable<Array<Stream | IErrorResult>> {
    let apiStramURI = `publicreturns/actual/${investmentProgramId}/stream/upload?fundId=${entityId}`;
    return this.api.postFile(`periodicdata/${apiStramURI}`, payload);
  }
}
