import { AuthenticationStepEnum, DefaultValidationMessages, meta, Meta, RoleTitle, ValidationStyleEnum, User, ROLETITLES } from "@aksia-monorepo/shared-ui";
import { View, VIEWS } from "../../interfaces/system.interface";

export class Credentials extends Meta {
    @meta({ alias: 'Username', hardNotEmpty: true, isInaudible: true })
    maxusername: string;
    @meta({ alias: 'Password', hardNotEmpty: true, isInaudible: true })
    maxpassword: string;
    @meta({ alias: 'Two factor code', hardNotEmpty: {
        type: ValidationStyleEnum.Hard,
        defaultMessage: DefaultValidationMessages.hardNotEmpty,
        validateRule: (self,value,target,prop) => {
            return (value || target.step !== AuthenticationStepEnum.Requested2FA) ? self.message = null : self.message = `${self.defaultMessage}`;
        }
    }, isInaudible: true })
    IPVerificationCode: number;
    step: AuthenticationStepEnum = AuthenticationStepEnum.NotAuthenticated;
}

export class O2User {
    username: string;
    abbr: string;
    fullname: string;
    roles: Array<RoleTitle>;
    defaultView: View;
    
    constructor(user: User) {
        this.username = user.username;
        this.abbr = user.username.substring(0,2)?.toUpperCase();
        this.fullname = user.fullName;
        this.roles = user.roles.filter( role => Object.values(ROLETITLES).includes(role));

        this.defaultView = VIEWS.DATA;
        if (this.roles.includes(ROLETITLES.LEGAL_DOC)){
            this.defaultView = VIEWS.LEGAL_DOC;
        }
        else if(this.roles.includes(ROLETITLES.RISK)){
            this.defaultView = VIEWS.RISK;
        }
        else if(this.roles.includes(ROLETITLES.INVESTMENT_RESEARCH)){
            this.defaultView = VIEWS.INVESTMENT_RESEARCH;
        }
        else if (this.roles.includes(ROLETITLES.DATA)){
            this.defaultView = VIEWS.DATA;
        }
    }
}
