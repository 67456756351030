import {
  DefaultValidationMessages,
  IMetaState,
  inMetaFactory,
  meta,
  Meta,
  ModelState,
  ValidationStyleEnum,
} from '@aksia-monorepo/shared-ui';
import { Type } from 'class-transformer';
import {
  EntityTypeEnum,
  PublicReturnFeeEnum,
  PublicReturnGeneralClassificationEnum,
  PublicReturnSourceEnum,
  StreamSourceEnum,
} from '../../enums/enums';

@inMetaFactory
export class Stream extends Meta implements IMetaState {
  entityId!: number;
  fundId?: number;
  entityTypeId?: EntityTypeEnum;
  classType = 'Stream';

  @Type(() => Date)
  @meta({ alias: 'As Of' })
  asOf: Date;

  @Type(() => Date)
  @meta({ alias: 'Weekly As Of' })
  estimationAsOf?: Date;

  @meta({
    alias: 'Source',
    hardNotEmpty: {
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotEmpty,
      validateRule: (self, value, target, prop) => {
        if (target['value'] && !value) {
          return (self.message = self.defaultMessage);
        } else {
          return (self.message = null);
        }
      },
    },
  })
  source?: StreamSourceEnum | PublicReturnSourceEnum;

  @meta({
    alias: 'Fees',
    source: PublicReturnFeeEnum.toKeyValue(),
    hardNotEmpty: {
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotEmpty,
      validateRule: (self, value, target, prop) => {
        if (target.isPublic && target.value && !value) {
          return (self.message = self.defaultMessage);
        } else {
          return (self.message = null);
        }
      },
    },
  })
  feeType?: PublicReturnFeeEnum;

  @meta({
    alias: 'Entity Type',
    source: PublicReturnGeneralClassificationEnum.toKeyValue(),
    hardNotEmpty: {
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotEmpty,
      validateRule: (self, value, target, prop) => {
        if (target.isPublic && target.value && !value) {
          return (self.message = self.defaultMessage);
        } else {
          return (self.message = null);
        }
      },
    },
  })
  classification: PublicReturnGeneralClassificationEnum;

  @meta({
    alias: 'Value',
    updates: (self, value) => {
      if (self instanceof Stream) {
        self.validate('source');
        self.validate('feeType');
        self.validate('classification');
      }
    },
  })
  value: number;

  get bgColorFromClassification(): string {
    if (this.markedForDeletion) return 'cell-bg-color-red';
    let bgColor = '';
    switch (this.classification) {
      case PublicReturnGeneralClassificationEnum.Master:
      case PublicReturnGeneralClassificationEnum.InvestmentProgram:
        bgColor = 'cell-bg-color-orangepink';
        break;
      case PublicReturnGeneralClassificationEnum.Backtested:
      case PublicReturnGeneralClassificationEnum.CarveOut:
      case PublicReturnGeneralClassificationEnum.Composite:
      case PublicReturnGeneralClassificationEnum.FundOfOne:
      case PublicReturnGeneralClassificationEnum.InternalOnly:
        bgColor = 'cell-bg-color-orange';
        break;
      default:
        bgColor = 'cell-bg-color-white';
        break;
    }
    if (this.estimationAsOf) {
      bgColor += ' estimate';
    }
    return bgColor;
  }

  get minDate(): Date {
    return new Date(this.asOf?.getFullYear(), this.asOf?.getMonth() - 1, 0);
  }

  get maxDate(): Date {
    return new Date(this.asOf?.getFullYear(), this.asOf?.getMonth() + 1, 1);
  }

  sources(streamEnum: any) {
    return streamEnum.toKeyValue();
  }

  markedForDeletion: boolean;

  @meta({ alias: 'isPublic' })
  isPublic: boolean;

  isNew: boolean;

  constructor(entityId?: number, entityTypeId?: number, asOf?: Date) {
    super();
    this.entityId = entityId;
    this.fundId = entityId;
    this.entityTypeId = entityTypeId;
    this.asOf = asOf;
    this.state = ModelState.Ready;
  }
}
