import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { plainToClass } from 'class-transformer';
import { AumService, BulkAUM } from './aum.service';
import { AUMSourceEnum, DataSourceEnum, EntityTypeEnum } from '../enums/enums';
import { AUM } from '../classes/aum/aum.model';
import { ManagementCompany, Program } from '../classes/company/company.model';
import { LoadingService } from './loading.service';
import { NoteService } from './note.service';
import { EntityService } from './entity.service';
import {
  AuthenticationService,
  ApiMicroService,
  ModelState,
} from '@aksia-monorepo/shared-ui';
import { first } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { IErrorResult, NoteDTO } from '../interfaces/system.interface';
import { AUMeta } from '../classes/aum/aum.meta';
import { HttpErrorResponse } from '@angular/common/http';
import { Worksheet, Comment, CellFormulaValue } from 'exceljs';
import { Title } from '@angular/platform-browser';
import { TaxonomyService } from './taxonomy.service';
import { Address } from '../classes/entities/entities.model';
import { CompanyUtils } from '../classes/company/company.utils';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends EntityService {
  private datePipe: DatePipe = new DatePipe('en-US');
  private declinedNotes: Array<{ key: string; value: string; desc: string }>;
  public importError = { type: null, message: null };

  constructor(
    private auth: AuthenticationService,
    private api: ApiMicroService,
    private aumService: AumService,
    private noteService: NoteService,
    protected taxService: TaxonomyService,
    loadingService: LoadingService,
    title: Title
  ) {
    super(loadingService, title, taxService);
  }

  //#region Public Methods

  public getCompany(entityId: number): ManagementCompany {
    //Reset Params
    this.importError = { type: null, message: null };
    this.noteService._o2Notes.clear();

    this.endPointsRequesting = 3;
    this.api.get(`basicdata/managementcompany/${entityId}`).subscribe(
      (response: any) => {
        this.endPointsRequesting += response.investmentPrograms.length;
        response.investmentPrograms = response.investmentPrograms.sort((a, b) =>
          a.name < b.name ? -1 : 1
        );
        this.localCompany = plainToClass(ManagementCompany, response);
        this.localCompany.addresses?.forEach(
          (addr, i) =>
            (addr.countryState = response.addresses?.find((addrDTO) =>
              CompanyUtils.compareAddress(addrDTO, addr)
            )?.state)
        );
        this.localCompany.funds = (response.investmentPrograms as any)?.flatMap(
          (ip) =>
            (ip as Program).funds?.map((fund) => {
              return { key: fund.fundId, value: fund.name };
            })
        );

        //Get AUM
        this.aumService
          .getHistoricalAUM(entityId, EntityTypeEnum.ManagementCompany)
          .subscribe(
            (aumPlain: BulkAUM) => {
              let companyAUM, companyAUMPlain;
              if (aumPlain?.aum?.length > 0) {
                companyAUMPlain = aumPlain.aum.filter(
                  (aum) => aum.entityTypeId == EntityTypeEnum.ManagementCompany
                );
              }
              companyAUM =
                companyAUMPlain?.length > 0
                  ? plainToClass(AUM, companyAUMPlain)
                  : [
                      new AUM(
                        this.localCompany.managementCompanyId,
                        EntityTypeEnum.ManagementCompany,
                        new Date()
                      ),
                    ];
              this.localCompany.aumMeta = new AUMeta(
                this.localCompany.managementCompanyId,
                EntityTypeEnum.ManagementCompany,
                this.localCompany.getInceptionDate(),
                this.localCompany.name
              );
              this.localCompany.aumMeta.initAUM(companyAUM);
              this.localCompany.aumMeta.updateSpreadsheet();
              this.localCompany.aumMeta.aumSpreadSheet.hasFullTrackRecord =
                true;
              this.localCompany.aumMeta.aumSpreadSheet.isVisible = true;
              this.localCompany.aumMeta.aumSpreadSheet.settings.markingAllowed =
                false;
              this.localCompany.aumMeta.aumSpreadSheet.showHistoryToggle =
                false;
              this.localCompany.aumMeta.aumSpreadSheet.showCloseToggle = false;

              this.localCompany.investmentPrograms.forEach((ip) => {
                ip.aumMeta = new AUMeta(
                  ip.investmentProgramId,
                  EntityTypeEnum.Program,
                  ip.inceptionDate,
                  ip.name
                );
                ip.aumMeta.classType = 'ProgramAUMeta';
                ip.aumMeta.initAUM([
                  new AUM(
                    ip.investmentProgramId,
                    EntityTypeEnum.Program,
                    new Date()
                  ),
                ]);
                ip.aumMeta.updateSpreadsheet();
                ip.aumMeta.aumSpreadSheet.settings.markingAllowed = false;
                console.warn('COMPLETED Program AUM');
                this.endPointResponsed();
              });

              console.warn('COMPLETED AUM');
              this.endPointResponsed();
            },
            (error) => {
              console.error(error);
              this.endPointResponsed();
            }
          );

        //Get Notes
        this.api
          .get(`metadata/${entityId}/${EntityTypeEnum.ManagementCompany}`)
          .subscribe((response) => {
            response.forEach((noteDTO) => {
              if (noteDTO.note) {
                let noteKV = this.noteService.toMapModel(
                  noteDTO,
                  this.localCompany
                );
                if (noteKV) {
                  const metaId = this.localCompany.metaId;
                  this.noteService._o2Notes.set(
                    `${noteKV.key}_${metaId}`,
                    noteKV.noteValue
                  );
                }
              }
            });
            console.warn('COMPLETED Notes');
            this.endPointResponsed();
          });

        console.warn('COMPLETED Company');
        this.endPointResponsed();
      },
      (error) => {
        console.error(error);
        this.endPointResponsed();
      }
    );
    return this.company;
  }

  public getInvestmentProgram(entityId: number): Observable<Program> {
    return this.api.get(`basicdata/investmentprogram/${entityId}`);
  }

  public savePage() {
    this.validateAndPrepare(EntityTypeEnum.ManagementCompany);

    if (
      [
        this.company.state,
        ...this.company.investmentPrograms.map((ip) => ip.aumMeta.state),
        this.company.aumMeta.state,
      ].includes(ModelState.HasError)
    ) {
      this.hasError = true;
      //return;
    }

    const isCompanyDirty = this.company.state === ModelState.IsDirty ? 1 : 0;
    const dirtyCompanyAUM =
      this.company.aumMeta.aum?.filter(
        (aum) => aum.state === ModelState.IsDirty
      ) ?? [];
    const dirtyProgramsByField = this.company.investmentPrograms?.filter(
      (program) => program.state === ModelState.IsDirty
    );
    const dirtyProgramsByAUM = this.company.investmentPrograms?.filter(
      (program) => program.aumMeta.state === ModelState.IsDirty
    );
    const areNotesDirty = this.noteService.state === ModelState.IsDirty ? 1 : 0;
    this.endPointsRequesting +=
      isCompanyDirty +
      (dirtyCompanyAUM?.length > 0 ? 1 : 0) +
      dirtyProgramsByField?.length +
      dirtyProgramsByAUM?.length +
      areNotesDirty;

    if (isCompanyDirty) {
      this.saveCompany();
    }

    if (
      dirtyProgramsByField?.length > 0 &&
      this.company.programState === ModelState.IsDirty
    ) {
      this.savePrograms(dirtyProgramsByField);
    }

    if (
      dirtyProgramsByAUM?.length > 0 &&
      this.company.programAUMState === ModelState.IsDirty
    ) {
      this.saveProgramAUM(dirtyProgramsByAUM);
    }

    if (
      dirtyCompanyAUM?.length > 0 &&
      this.company.aumMeta.state === ModelState.IsDirty
    ) {
      this.saveCompanyAUM(dirtyCompanyAUM);
    }

    if (areNotesDirty) {
      this.noteService.updateNotes(
        this.company,
        this.endPointResponsed.bind(this)
      );
    }
  }

  public saveCompany() {
    this.company.state = ModelState.Saving;
    this.updateCompanyBasicData(this.localCompany)
      .pipe(first())
      .subscribe({
        error: (error) => {
          console.error(error);
          this.serverError += JSON.stringify(error);
          this.hasError = true;
          this.localCompany.state = ModelState.NotSaved;
          this.endPointResponsed();
        },
        complete: () => {
          this.localCompany.state = ModelState.Saved;
          console.warn('Company Basic Data Saved!');
          this.endPointResponsed();
        },
      });
  }

  public savePrograms(dirtyProgramsByField: Array<Program>) {
    this.company.programState = ModelState.Saving;

    if (dirtyProgramsByField?.length > 0) {
      dirtyProgramsByField.forEach((program) => {
        program.state = ModelState.Saving;
        this.updateInvestmentProgramData(program)
          .pipe(first())
          .subscribe({
            error: (error) => {
              console.error(error);
              this.serverError += JSON.stringify(error);
              this.hasError = true;
              program.state = ModelState.NotSaved;
              this.localCompany.programState = ModelState.NotSaved;
              this.endPointResponsed();
            },
            complete: () => {
              console.warn('Program Saved!');
              program.state = ModelState.Saved;
              if (this.localCompany.programState !== ModelState.NotSaved) {
                this.localCompany.programState = ModelState.Saved;
              }
              this.endPointResponsed();
            },
          });
      });
    }
  }

  public saveProgramAUM(dirtyProgramsByAUM: Array<Program>) {
    this.company.programAUMState = ModelState.Saving;

    if (dirtyProgramsByAUM?.length > 0) {
      dirtyProgramsByAUM.forEach((program) => {
        program.aumMeta.state = ModelState.Saving;
        const dirtyAUM = program.aumMeta.aum.filter(
          (aum) => aum.state === ModelState.IsDirty
        );
        if (dirtyAUM?.length > 0) {
          this.aumService
            .updateAUM(dirtyAUM)
            .pipe(first())
            .subscribe({
              error: (error: HttpErrorResponse) => {
                console.error(error);
                this.serverError += JSON.stringify(error);
                this.hasError = true;
                program.aumMeta.state = ModelState.NotSaved;
                program.aumMeta.updateSpreadsheet();
                this.localCompany.programAUMState = ModelState.NotSaved;
                this.endPointResponsed();
              },
              complete: () => {
                if (program.aumMeta.state !== ModelState.NotSaved) {
                  program.aumMeta.state = ModelState.Saved;
                  if (
                    this.localCompany.programAUMState !== ModelState.NotSaved
                  ) {
                    this.localCompany.programAUMState = ModelState.Saved;
                    let localProgram =
                      this.localCompany.investmentPrograms?.find(
                        (ip) =>
                          ip.investmentProgramId === program.investmentProgramId
                      );

                    localProgram.aumMeta.aum?.forEach((aum) => {
                      if (aum.markedForDeletion) {
                        aum.source = undefined;
                        aum.markedForDeletion = undefined;
                      }

                      aum.isNew = false;
                      aum.state = ModelState.Ready;
                    });
                    localProgram.aumMeta.updateSpreadsheet();
                  }
                  console.warn('Program AUM Saved!');
                  this.endPointResponsed();
                }
              },
            });
        } else {
          this.endPointResponsed();
        }
      });
    }
  }

  public saveCompanyAUM(dirtyAUM: Array<AUM>) {
    this.company.aumMeta.state = ModelState.Saving;
    this.aumService
      .updateAUM(dirtyAUM)
      .pipe(first())
      .subscribe({
        error: (error: HttpErrorResponse) => {
          console.error(error);
          this.serverError += JSON.stringify(error);
          this.hasError = true;
          this.localCompany.aumMeta.state = ModelState.NotSaved;
          this.localCompany.aumMeta.updateSpreadsheet();
          this.endPointResponsed();
        },
        complete: () => {
          if (this.localCompany.aumMeta.state !== ModelState.NotSaved) {
            this.localCompany.aumMeta.state = ModelState.Saved;
            this.localCompany.aumMeta.aum?.forEach((aum) => {
              if (aum.markedForDeletion) {
                aum.source = undefined;
                aum.markedForDeletion = undefined;
              }

              aum.isNew = false;
              aum.state = ModelState.Ready;
            });
            this.localCompany.aumMeta.updateSpreadsheet();
          }
          console.warn('Company AUM Saved!');
          this.endPointResponsed();
        },
      });
  }

  public importFromExcel(worksheet: Worksheet): void {
    this.validateAndPrepare(EntityTypeEnum.ManagementCompany);
    this.declinedNotes = [];
    this.endPointsRequesting = 1;

    let col: string = 'E';

    if (worksheet.getRow(59).getCell('D').value === 'GP Information') {
      //As Of
      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        this.localCompany,
        'dataAsOfDate',
        10,
        col,
        'date'
      );

      //Source
      this.localCompany.dataSource = DataSourceEnum.AksiaTemplate;

      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        this.localCompany,
        'inceptionDate',
        60,
        col,
        'date'
      );
      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        this.localCompany,
        'totalStaff',
        61,
        col,
        'amount'
      );

      //AUM
      if (
        !!worksheet.getRow(62).getCell(col).value &&
        !!worksheet.getRow(63).getCell(col).value
      ) {
        this.localCompany.aumMeta.source = AUMSourceEnum.AksiaTemplate;
        this.importCell(
          EntityTypeEnum.ManagementCompany,
          worksheet,
          this.localCompany.aumMeta,
          'selectedAsOf',
          63,
          col,
          'date'
        );
        this.importCell(
          EntityTypeEnum.ManagementCompany,
          worksheet,
          this.localCompany.aumMeta.selectedAum,
          'amount',
          62,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.ManagementCompany,
          worksheet,
          this.localCompany.aumMeta.selectedAum,
          'asOfDate',
          63,
          col,
          'date'
        );
        this.localCompany.aumMeta.selectedAum.source =
          AUMSourceEnum.AksiaTemplate;
        this.localCompany.aumMeta.selectedAum.state = ModelState.IsDirty;
      }

      if (!(this.localCompany.addresses?.length > 0)) {
        this.localCompany.addresses = [new Address()];
        this.localCompany.addresses[0].isPrimary = true;
        this.localCompany.addresses[0].entityTypeId =
          this.localCompany.managementCompanyId;
      }
      const address =
        this.localCompany.addresses.find((addr) => addr.isPrimary) ??
        this.localCompany.addresses[0];
      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        address,
        'address1',
        65,
        col
      );
      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        address,
        'address2',
        66,
        col
      );
      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        address,
        'city',
        67,
        col
      );
      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        address,
        'countryState',
        68,
        col
      );
      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        address,
        'zip',
        69,
        col
      );
      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        address,
        'country',
        70,
        col
      );
      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        address,
        'phone',
        71,
        col
      );

      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        this.localCompany,
        'url',
        72,
        col
      );
      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        this.localCompany,
        'generalEmail',
        73,
        col
      );
    } else if (worksheet.getRow(62).getCell('D').value === 'GP Information') {
      //As Of
      this.importCell(
        EntityTypeEnum.Fund,
        worksheet,
        this.localCompany,
        'dataAsOfDate',
        10,
        col,
        'date'
      );

      //Source
      this.localCompany.dataSource = DataSourceEnum.AksiaTemplate;

      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        this.localCompany,
        'inceptionDate',
        63,
        col,
        'date'
      );
      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        this.localCompany,
        'totalStaff',
        65,
        col,
        'amount'
      );

      //AUM
      if (
        !!worksheet.getRow(66).getCell(col).value &&
        !!worksheet.getRow(67).getCell(col).value
      ) {
        this.localCompany.aumMeta.source = AUMSourceEnum.AksiaTemplate;
        this.importCell(
          EntityTypeEnum.ManagementCompany,
          worksheet,
          this.localCompany.aumMeta,
          'selectedAsOf',
          67,
          col,
          'date'
        );
        this.importCell(
          EntityTypeEnum.ManagementCompany,
          worksheet,
          this.localCompany.aumMeta.selectedAum,
          'amount',
          66,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.ManagementCompany,
          worksheet,
          this.localCompany.aumMeta.selectedAum,
          'asOfDate',
          67,
          col,
          'date'
        );
        this.localCompany.aumMeta.selectedAum.source =
          AUMSourceEnum.AksiaTemplate;
        this.localCompany.aumMeta.selectedAum.state = ModelState.IsDirty;
      }

      if (!(this.localCompany.addresses?.length > 0)) {
        this.localCompany.addresses = [new Address()];
        this.localCompany.addresses[0].isPrimary = true;
        this.localCompany.addresses[0].entityTypeId =
          this.localCompany.managementCompanyId;
      }
      const address =
        this.localCompany.addresses.find((addr) => addr.isPrimary) ??
        this.localCompany.addresses[0];
      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        address,
        'address1',
        68,
        col
      );
      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        address,
        'address2',
        69,
        col
      );
      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        address,
        'city',
        70,
        col
      );
      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        address,
        'countryState',
        71,
        col
      );
      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        address,
        'zip',
        72,
        col
      );
      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        address,
        'country',
        73,
        col
      );
      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        address,
        'phone',
        74,
        col
      );

      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        this.localCompany,
        'url',
        75,
        col
      );
      this.importCell(
        EntityTypeEnum.ManagementCompany,
        worksheet,
        this.localCompany,
        'generalEmail',
        76,
        col
      );
    } else {
      this.importError.type = 'HasErrors';
      this.importError.message = `Possible Older Template Version`;
    }
    if (this.declinedNotes?.length > 0) {
      const notesInfo = this.declinedNotes
        .map((dn) => `${dn.key} ${dn.value} \n${dn.desc}`)
        .join('\n\n');
      this.importError.type = 'HasWarnings';
      this.importError.message = `The following data and/or notes could not be imported:\n\r ${notesInfo}`;
    }
    this.endPointResponsed();
    if (this.importError.type !== 'HasErrors') {
      this.company.state = ModelState.IsDirty;
      this.company.aumMeta.state = ModelState.IsDirty;
      this.noteService.state = ModelState.IsDirty;
    }
  }

  public updateInvestmentProgramData(
    program: Program
  ): Observable<Program | IErrorResult> {
    let programDTO = program.toPlain();
    delete programDTO.auditURL;
    delete programDTO.auditURLParams;
    delete programDTO.classType;
    delete programDTO.group;
    delete programDTO._programLiquidity;
    delete programDTO.taxonomyId;
    return this.api.put(`basicdata/investmentprogram`, programDTO);
  }

  //#endregion

  //#region Private Methods

  private updateCompanyBasicData(
    o2Company: ManagementCompany
  ): Observable<any> {
    let o2CompanyDTO = o2Company.toPlain();
    delete o2CompanyDTO.auditURL;
    delete o2CompanyDTO.auditURLParams;
    delete o2CompanyDTO.classType;
    delete o2CompanyDTO.group;
    delete o2CompanyDTO.aumMeta;
    delete o2CompanyDTO.funds;
    delete o2CompanyDTO.investmentPrograms;
    delete o2CompanyDTO.programAUMState;
    delete o2CompanyDTO.programState;
    delete o2CompanyDTO._notes;
    return this.api.put(`basicdata/managementcompany`, o2CompanyDTO);
  }

  private importCell(
    entityType: EntityTypeEnum,
    worksheet: Worksheet,
    prop: unknown,
    propName: string,
    rowNum: number,
    cellNum: string,
    dataType: 'enum' | 'date' | 'rate' | 'amount' = null,
    enumKeyValue: Array<{ value: string; key: any }> = null
  ) {
    const entityId = this.localCompany.managementCompanyId;
    const cell = worksheet.getRow(rowNum).getCell(cellNum);
    const importedValue = cell?.formula
      ? (cell?.value as CellFormulaValue)?.result
      : cell?.value;
    const importedNoteOrComment = cell?.note;

    if (importedValue || importedNoteOrComment) {
      //Value import
      switch (dataType) {
        case 'date': {
          let importedDate = new Date(importedValue as string);
          if (!isNaN(importedDate?.getTime())) {
            prop[propName] = importedDate;
          } else {
            const fieldName = worksheet.getRow(rowNum).getCell('D')
              ?.value as string;
            this.declinedNotes.push({
              key: fieldName,
              value: `${importedDate}`,
              desc: ' - Check if cell value is a date of proper format (ex.: 3/31/2021) instead of a formula or other text.',
            });
          }
          break;
        }
        case 'rate': {
          if (!isNaN(importedValue as number)) {
            prop[propName] = (importedValue as number) * 100;
          } else {
            const fieldName = worksheet.getRow(rowNum).getCell('D')
              ?.value as string;
            this.declinedNotes.push({
              key: fieldName,
              value: `${importedValue}`,
              desc: ' - Check if cell value is numeric (ex.: 50% or 9.00) instead of a formula or other text.',
            });
          }

          break;
        }
        case 'amount': {
          if (!isNaN(importedValue as number)) {
            prop[propName] = importedValue;
          } else {
            const fieldName = worksheet.getRow(rowNum).getCell('D')
              ?.value as string;
            this.declinedNotes.push({
              key: fieldName,
              value: `${importedValue}`,
              desc: ' - Check if cell value is numeric (ex.: $10,000,000 or 1.60) instead of a formula or other text.',
            });
          }

          break;
        }
        case 'enum': {
          let enumFound = enumKeyValue.find(
            (kv) =>
              kv.value ===
              (worksheet.getRow(rowNum).getCell(cellNum).value as string)
          );
          if (!!enumFound) {
            prop[propName] = enumFound.key;
          } else {
            const fieldName = worksheet.getRow(rowNum).getCell('D')
              ?.value as string;
            this.declinedNotes.push({
              key: fieldName,
              value: `${worksheet.getRow(rowNum).getCell(cellNum).value}`,
              desc: ` - Check if cell value is among: ${enumKeyValue
                .map((kv) => kv.value)
                .join(', ')}`,
            });
          }
          break;
        }
        default:
          prop[propName] = importedValue;
      }

      //Note import
      if (
        importedNoteOrComment &&
        ['inceptionDate', 'totalStaff'].includes(propName)
      ) {
        const noteText =
          typeof importedNoteOrComment === 'string'
            ? importedNoteOrComment
            : (importedNoteOrComment as Comment).texts[0]?.text;

        let note = this.noteService._o2Notes.get(
          `${propName}_${entityType}_${entityId}`
        );
        if (note) {
          note.note = noteText;
          note._isDirty = true;
          this.noteService._o2Notes.set(
            `${propName}_${entityType}_${entityId}`,
            note
          );
        } else {
          const newNote: NoteDTO = {
            entityId: entityId,
            entityTypeId: entityType,
            fieldName: propName,
            modifiedBy: this.auth.user.username,
            modifiedOn: new Date().toISOString(),
            note: noteText,
            system: 'O2',
          };
          const noteKV = this.noteService.toMapModel(
            newNote,
            this.localCompany
          );
          noteKV.noteValue._isDirty = true;
          this.noteService._o2Notes.set(
            `${propName}_${entityType}_${entityId}`,
            noteKV.noteValue
          );
        }
      }
    }
  }

  //#endregion
}
