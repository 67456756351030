//#region Array Methods

//Flattens nested object array properties
export function flatDeep(
  haystack: Array<any>,
  needle: string,
  sep: string = '.'
) {
  let needles: Array<string> = needle?.split(sep);
  let prop = needles.shift();
  if (needles.length >= 0) {
    return haystack.reduce((acc, item) => {
      if (Array.isArray(item[prop])) {
        return acc.concat(flatDeep(item[prop], needles.join(sep), sep));
      } else {
        return acc.concat(item[prop]);
      }
    }, []);
  } else {
    return haystack.slice();
  }
}

export function groupBy(
  haystack: Array<unknown>,
  criteria: string,
  separator: string = '_',
  toArray?: boolean
): unknown | Array<unknown> {
  const newObj = haystack.reduce((acc, current) => {
    const currentCriteria = Array.isArray(current[criteria])
      ? current[criteria].join(separator)
      : current[criteria];
    if (!acc[currentCriteria]) {
      acc[currentCriteria] = [];
    }
    acc[currentCriteria].push(current);
    return acc;
  }, {});
  return toArray ? Object.entries(newObj) : newObj;
}

//#endregion

//#region String Methods

//Converts first letter of string to lower
export function toCamelCase(value: string) {
  let out = !value
    ? ''
    : isUpperCase(value[1]) && isUpperCase(value[2])
    ? value
    : value[0].toLowerCase() + value.slice(1);
  return out;
}

//Converts first letter of string to Upper
export function toPascalCase(value: string) {
  let out = !value ? '' : value[0].toUpperCase() + value.slice(1);
  return out;
}

export const isUpperCase = (string) => /^[A-Z]*$/.test(string);

export function copyToClipboard(value: string) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(value);
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = value;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }
}

//#endregion

//#region Date Methods

//Converts local date to ISO String with local date numbers
export function toLocalISOString(value: Date) {
  return value
    ? `${value.getFullYear()}-${(value.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${value
        .getDate()
        .toString()
        .padStart(2, '0')}T00:00:00.000Z`
    : undefined;
}

export function toLocalDateString(value: Date) {
  return value
    ? `${(value.getMonth() + 1).toString().padStart(2, '0')}/${value
        .getDate()
        .toString()
        .padStart(2, '0')}/${value.getFullYear()}`
    : undefined;
}

export function toLocalDate(value: string) {
  return value ? new Date(value.replace('Z', '')) : value;
}

export function getDifferenceInDays(dateA: Date, dateB: Date) {
  if (dateA !== null && dateB != null) {
    return (dateA.getTime() - dateB.getTime()) / (1000 * 3600 * 24);
  }
}

export function toDateFormat(value: Date, format: string = 'mm/dd/yyyy') {
  return value
    ? format
        .replace('dd', value.getDate().toString())
        .replace('MMM', value.toLocaleString('en', { month: 'short' }))
        .replace('mm', (value.getMonth() + 1).toString())
        .replace('yyyy', value.getFullYear().toString())
    : null;
}

export function toEndOfMonth(value: Date) {
  return value ? new Date(value.getFullYear(), value.getMonth() + 1, 0) : null;
}

//#endregion

//#region Number Methods

export function getAbbr(
  value: number,
  abbrSmall: boolean = false,
  decimals: number = 3
): string {
  let suffix = '';
  let abbr: number;

  if ((value as unknown) === '') return undefined;

  if (Math.abs(value) < 1000000) {
    abbr = value;
  } else if (Math.abs(value) < 1000000000) {
    abbr = value / 1000000;
    suffix = abbrSmall ? 'm' : ' million';
  } else if (Math.abs(value) < 1000000000000) {
    abbr = value / 1000000000;
    suffix = abbrSmall ? 'b' : ' billion';
  } else {
    abbr = value / 1000000000000;
    suffix = abbrSmall ? 't' : ' trillion';
  }

  const final = Number.isInteger(abbr)
    ? parseInt(abbr?.toString()).toString()
    : Number(abbr?.toFixed(decimals)).toString();
  return `${final.replace(currencyExp, ',')}${suffix}`;
}

export const currencyExp = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g;

//#endregion

//#region JWT

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );
  return JSON.parse(jsonPayload);
}

//#endregion

export class UObject {
  public static findPropPath(obj, predicate): any {
    const discoveredObjects = []; // For checking for cyclic object
    const path = []; // The current path being searched
    const results = []; // The array of paths that satify the predicate === true
    if (!obj && (typeof obj !== 'object' || Array.isArray(obj))) {
      console.error(
        'First argument of findPropPath is not the correct type Object'
      );
      throw new TypeError(
        'First argument of findPropPath is not the correct type Object'
      );
    }
    if (typeof predicate !== 'function') {
      console.error('Predicate is not a function');
      throw new TypeError('Predicate is not a function');
    }
    (function find(obj) {
      for (const key of Object.keys(obj)) {
        // use only enumrable own properties.
        if (predicate(key, path, obj) === true) {
          // Found a path
          path.push(key); // push the key
          results.push(path.join('.')); // Add the found path to results
          path.pop(); // remove the key.
        }
        const o = obj[key]; // The next object to be searched
        if (o && typeof o === 'object' && !Array.isArray(o)) {
          // check for null then type object
          if (!discoveredObjects.find((obj) => obj === o)) {
            // check for cyclic link
            path.push(key);
            discoveredObjects.push(o);
            find(o);
            path.pop();
          }
        }
      }
    })(obj);
    return results;
  }
}
