/* eslint-disable @typescript-eslint/member-ordering */
import { Transform, Type } from 'class-transformer';
import {
  FundStructureEnum,
  LiquidityStructureEnum,
  SimpleAnswerEnum,
  DataSourceEnum,
  VEntityTypeEnum,
  LeverageTypeEnum,
  CurrencyEnum,
  ProgramStatusEnum,
  CloseDateTypeEnum,
  HedgeFundStructureEnum,
  FundStatusEnum,
  FundRaisingStatusEnum,
  ThirdPartyProviderEnum,
  ReportingSourceEnum,
} from '../../enums/enums';
import {
  DefaultValidationMessages,
  IMetaState,
  ModelState,
  ValidationStyleEnum,
  toLocalDate,
} from '@aksia-monorepo/shared-ui';
import { meta, Meta } from '@aksia-monorepo/shared-ui';
import { AUMeta } from '../aum/aum.meta';
import { OpenShareClass } from '../shareclass/shareclass.open';
import { ClosedShareClass } from '../shareclass/shareclass.closed';
import { HybridShareClass } from '../shareclass/shareclass.hybrid';
import { ManagementCompany, Program } from '../company/company.model';
import { ClosedEndDetails } from '../fund/fund.closedEndDetails';
import { HybridDetails } from '../fund/fund.hybridDetails';
import { Leverage, MasterFund } from '../entities/entities.model';
import { CoInvestmentDetails } from './fund.coInvestmentDetails';
import { SecondaryDetails } from './fund.secondaryDetails';
import { EntityTag, Taxonomy, TaxonomyMeta } from '../taxonomy/taxonomy.meta';
import { LeverageMeta } from '../leverage/leverage.meta';
import { StreamMeta } from '../stream/stream.meta';

export class Fund extends Meta implements IMetaState {
  classType = 'Fund';
  fundId: number = null;
  managementCompanyId: number = null;
  managementCompanyName: string = null;
  investmentProgramId: number = null;
  investmentProgramName: string = null;
  type: string = null;
  __group = 'name';
  vintage: number;

  @meta({ alias: 'Fund Name', hardNotEmpty: true })
  name: string = null;

  //#region <<< Section - Program >>>

  investmentProgram?: Program;

  //#endregion

  //#region <<< Section - Historical Info >>>

  @meta({ alias: 'Former Names', isInaudible: true })
  formerNames?: string;

  @meta({ alias: 'Name is Placeholder', isInaudible: true })
  nameIsPlaceholder?: boolean;

  @meta({ alias: 'High Conviction', isInaudible: true })
  highConviction?: boolean;

  @meta({ alias: 'Key', isInaudible: true })
  key?: boolean;

  //#endregion

  //#region <<< Section - Description >>>

  @meta({ alias: 'Internal Notes', hardNotMoreThan: 4000, isInaudible: true })
  shortDescription?: string;

  //#endregion

  //#region <<< Section - Overview >>>

  @meta({ alias: 'Overview', hardNotMoreThan: 4000, isInaudible: true })
  fundOverview?: string;

  //#endregion

  //#region <<< Section - Aksia Perspective >>>

  @meta({
    alias: 'Aksia Perspective',
    hardNotEmpty: {
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotEmpty,
      validateRule: (self, value, target, prop) => {
        return target.aksiaPerspectiveAsOf && !value
          ? (self.message = self.defaultMessage)
          : (self.message = null);
      },
    },
  })
  aksiaPerspective?: string;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({
    alias: 'As of',
    hardNotEmpty: {
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotEmpty,
      validateRule: (self, value, target, prop) => {
        return target.aksiaPerspective && !value
          ? (self.message = self.defaultMessage)
          : (self.message = null);
      },
    },
    softNotMoreThan: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotMoreThan,
      validateRule: (self, value, target, prop) => {
        return target.aksiaPerspectiveAsOf > new Date()
          ? (self.message = 'Is usually not occuring in the future')
          : (self.message = null);
      },
    },
  })
  aksiaPerspectiveAsOf?: Date;

  //#endregion

  //#region <<< Section - Periodic Streams >>>

  @meta({ alias: '1st of Month' })
  isAumFirstMonth?: boolean;

  @meta({ alias: 'Quarterly' })
  isAumQuarterly?: boolean;

  aumMeta: AUMeta;

  publicreturnsMeta: StreamMeta;

  netIRRMeta: StreamMeta;

  grossIRRMeta: StreamMeta;

  grossMOICMeta: StreamMeta;

  netMOICMeta: StreamMeta;

  netDPIMeta: StreamMeta;

  netRVPIMeta: StreamMeta;

  investedCapitalMeta: StreamMeta;

  //#endregion

  //#region <<< Section - Taxonomy >>>

  @meta({
    alias: 'Asset Class',
  })
  assetClassId?: number;

  sectorId?: number;
  strategyId?: number;
  substrategyId?: number;
  primaryRegionId?: number;

  @Type(() => EntityTag)
  quantumTags?: Array<EntityTag>;

  @Transform(() => new TaxonomyMeta(), { toClassOnly: true })
  @meta({ navigation: true })
  taxonomyMeta: TaxonomyMeta = new TaxonomyMeta();

  @meta({
    alias: 'Woman/Minority Ownership %',
    isInaudible: true,
    hardNotMoreThan: 100,
    updates: (self: Meta, value: number) => {
      if (self instanceof Fund) {
        if (!value) {
          self.taxonomyMeta.wmdOwnershipTag = undefined;
        } else if (value <= 33) {
          const tagId = Taxonomy.wmdOwnership.find(
            (tag) => tag.name === '<=33%'
          )?.id;
          self.taxonomyMeta.wmdOwnershipTag = tagId;
        } else if (value > 33 && value <= 50) {
          const tagId = Taxonomy.wmdOwnership.find(
            (tag) => tag.name === '>33%'
          )?.id;
          self.taxonomyMeta.wmdOwnershipTag = tagId;
        } else {
          const tagId = Taxonomy.wmdOwnership.find(
            (tag) => tag.name === '>50'
          )?.id;
          self.taxonomyMeta.wmdOwnershipTag = tagId;
        }
      }
    },
  })
  esgWomanMinorityDisabledOwnership?: number;

  @meta({
    alias: 'Woman Ownership %',
    isInaudible: true,
    hardNotMoreThan: 100,
  })
  womanOwnership?: number;

  @meta({
    alias: 'Minority Ownership %',
    isInaudible: true,
    hardNotMoreThan: 100,
  })
  minorityOwnership?: number;

  //#endregion

  //#region <<< Section - Basic Info >>>

  @meta({ alias: 'Name of Document(s)' })
  physicalFileName?: string;

  @meta({
    alias: 'Source',
    isGlobalAuditor: true,
    updates: (self, value) => {
      if (self instanceof Fund) {
        self.shareClasses.forEach(
          (sh) => (sh.source = (value as DataSourceEnum) ?? undefined)
        );
      }
    },
  })
  dataSource?: DataSourceEnum;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({
    alias: 'As of',
    isGlobalAuditor: true,
    updates: (self, value) => {
      if (self instanceof Fund) {
        self.shareClasses.forEach(
          (sh) => (sh.asOfDate = (value as Date) ?? undefined)
        );
      }
    },
  })
  dataAsOfDate?: Date;

  @meta({ alias: 'Liquidity Structure' })
  liquidityStructure: LiquidityStructureEnum = null;

  @meta({
    alias: 'Liquidity Type',
    source: LiquidityStructureEnum.toKeyValue().filter(
      (kv) => kv.key !== LiquidityStructureEnum.Hybrid
    ),
  })
  liquidityType: LiquidityStructureEnum = null;

  @meta({ alias: 'Entity Type' })
  vEntityType: VEntityTypeEnum;

  @meta({
    alias: 'Fund Structure',
    updates: (self, value) => {
      if (self instanceof Fund) {
        //TODO: Check if other areas need that condition for plainToClass cases
        if (self.hasModified('legalStructure')) {
          self.removeAll('masterFunds');
          self.masterFunds = [];

          if (value !== FundStructureEnum.Stand_alone) {
            self.actsAsMasterFund = undefined;
          }
          if (
            [
              FundStructureEnum.Intermediate__Master,
              FundStructureEnum.Master_feeder,
              FundStructureEnum.UmbrellaMaster_feeder,
            ].includes(value as FundStructureEnum)
          ) {
            self.add('masterFunds');
          } else if (value === FundStructureEnum.MultipleMasters) {
            self.add('masterFunds');
            self.add('masterFunds');
          }
        }
      }
    },
  })
  legalStructure?: FundStructureEnum;

  @meta({
    alias: 'Hedge Fund Status',
    source: HedgeFundStructureEnum.toKeyValue(),
  })
  openEndStatus?: HedgeFundStructureEnum;

  @Type(() => MasterFund)
  @meta({
    navigation: true,
    adds: 'MasterFund',
    removes: 'hard',
  })
  masterFunds: Array<MasterFund>;

  @meta({ alias: 'MFN Provision', softNotEmpty: true })
  mfnProvision?: SimpleAnswerEnum;

  @meta({ alias: 'Acts as a Master Fund' })
  actsAsMasterFund?: boolean;

  //#endregion

  //#region <<< Section - Date Info >>>

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({
    alias: 'Date of Formation',
    softNotMoreThan: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotMoreThan,
      validateRule: (self, value, target, prop) => {
        return target.dateOfFormation > new Date()
          ? (self.message = 'Is usually not occuring in the future')
          : (self.message = null);
      },
    },
    updates: (self, value) => {
      if (self instanceof Fund) {
        if (self.aumMeta) {
          if (!self.commencementOfOperations) {
            self.aumMeta.aumStartDate = value as Date;
            if (
              self.aumMeta?.aumSpreadSheet &&
              self.aumMeta?.updateSpreadsheet
            ) {
              self.aumMeta?.updateSpreadsheet();
            }
          }
        }
        self.closedEndDetails?.closedEndKeyTerm?.validate?.('effectiveDate');
      }
    },
  })
  dateOfFormation?: Date;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({
    alias: 'Commencement of Operations',
    softNotLessThan: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotLessThan,
      validateRule(self, value, target, prop) {
        if (target instanceof Fund) {
          return (self.message =
            target.dateOfFormation?.getTime() >
            target.commencementOfOperations?.getTime()
              ? `Is usually occuring after Date of Formation`
              : (self.message = null));
        }
      },
    },
    softNotMoreThan: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotMoreThan,
      validateRule: (self, value, target, prop) => {
        return target.commencementOfOperations > new Date()
          ? (self.message = 'Is usually not occuring in the future')
          : (self.message = null);
      },
    },
    updates: (self, value) => {
      if (self instanceof Fund && self.aumMeta) {
        self.aumMeta.aumStartDate = (value as Date) ?? self.dateOfFormation;
        if (self.aumMeta?.aumSpreadSheet && self.aumMeta?.updateSpreadsheet) {
          self.aumMeta?.updateSpreadsheet();
        }
      }
      if (self instanceof Fund && self.publicreturnsMeta) {
        self.publicreturnsMeta.streamStartDate = value as Date;
        if (
          self.publicreturnsMeta?.streamSpreadSheet &&
          self.publicreturnsMeta?.updateSpreadsheet
        ) {
          self.publicreturnsMeta?.updateSpreadsheet();
        }
      }
    },
  })
  commencementOfOperations?: Date;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({
    alias: 'Date of 1st Management Fee Accrual',
    softNotMoreThan: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotMoreThan,
      validateRule: (self, value, target, prop) => {
        return target.firstMgmtFeeAccrualDate > new Date()
          ? (self.message = 'Is usually not occuring in the future')
          : (self.message = null);
      },
    },
  })
  firstMgmtFeeAccrualDate?: Date;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({
    alias: 'Date of 1st Drawdown Due Date',
    softNotMoreThan: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotMoreThan,
      validateRule: (self, value, target, prop) => {
        return target.firstDrawdownDueDate > new Date()
          ? (self.message = 'Is usually not occuring in the future')
          : (self.message = null);
      },
    },
  })
  firstDrawdownDueDate?: Date;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({
    alias: '1st Investment Date',
    softNotMoreThan: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotMoreThan,
      validateRule: (self, value, target, prop) => {
        return target.firstInvestmentDate > new Date()
          ? (self.message = 'Is usually not occuring in the future')
          : (self.message = null);
      },
    },
  })
  firstInvestmentDate?: Date;

  @meta({ alias: 'Final Close Date', source: CloseDateTypeEnum.toKeyValue() }) // Final Close Date type
  finalCloseDateType?: CloseDateTypeEnum;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({
    alias: 'Final Close Date (mm/dd/yyyy)',
    hardNotLessThan: {
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreOrEqualTo,
      validateRule(self, value, target, prop) {
        if (target instanceof Fund) {
          return (self.message =
            target.firstCloseDate?.getTime &&
            target.firstCloseDate?.getTime() > target.finalCloseDate?.getTime()
              ? 'Final Close should occur on or after First Close'
              : null);
        }
      },
    },
    softNotMoreThan: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotMoreThan,
      validateRule: (self, value, target, prop) => {
        return target.finalCloseDate > new Date()
          ? (self.message = 'Is usually not occuring in the future')
          : (self.message = null);
      },
    },
    hardNotLessOrEqualTo: {
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreOrEqualTo,
      validateRule(self, value, target, prop) {
        if (target instanceof Fund) {
          let foundLater = target.closedEndDetails?.interimDates?.find(
            (interimDate) =>
              interimDate.date?.getTime &&
              interimDate.date?.getTime() > target.finalCloseDate?.getTime()
          );
          let foundEqual = target.closedEndDetails?.interimDates?.find(
            (interimDate) =>
              interimDate.date?.getTime &&
              interimDate.date?.getTime() === target.finalCloseDate?.getTime()
          );
          return (self.message = foundLater
            ? 'Final Close should occur after Interim Close'
            : foundEqual
            ? 'Interim Close should capture a closing between First and Final Close'
            : null);
        }
      },
    },
  }) // Final Close Date or Expected Final Close
  finalCloseDate?: Date;

  @meta({ alias: 'Final Close Date (Note)' })
  finalCloseDateNote?: string;

  @meta({ alias: 'First Close Date', source: CloseDateTypeEnum.toKeyValue() }) // First Close Date type
  firstCloseDateType?: CloseDateTypeEnum;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({
    alias: 'First Close Date (mm/dd/yyyy)',
    softNotLessThan: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotLessThan,
      validateRule(self, value, target, prop) {
        if (target instanceof Fund) {
          return (self.message =
            target.firstCloseDate?.getTime() < target.dateOfFormation?.getTime()
              ? `Is usually occuring after Date of Formation`
              : (self.message = null));
        }
      },
    },
    softNotMoreThan: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotMoreThan,
      validateRule: (self, value, target, prop) => {
        return target.firstCloseDate > new Date()
          ? (self.message = 'Is usually not occuring in the future')
          : (self.message = null);
      },
    },
    hardNotLessOrEqualTo: {
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotLessOrEqualTo,
      validateRule(self, value, target, prop) {
        if (target instanceof Fund) {
          let foundEarlier = target.closedEndDetails?.interimDates?.find(
            (interimDate) =>
              interimDate.date?.getTime &&
              interimDate.date?.getTime() < target.firstCloseDate?.getTime()
          );
          let foundEqual = target.closedEndDetails?.interimDates?.find(
            (interimDate) =>
              interimDate.date?.getTime &&
              interimDate.date?.getTime() === target.firstCloseDate?.getTime()
          );
          return (self.message = foundEarlier
            ? 'First Close should occur before Interim Close'
            : foundEqual
            ? 'Interim Close should capture a closing between First and Final Close'
            : null);
        }
      },
    },
  }) // First Close Date or Expected First Close
  firstCloseDate?: Date;

  @meta({ alias: 'First Close Date (Note)' })
  firstCloseDateNote?: string;

  //#endregion

  //#region <<< Section - Leverage >>>

  @meta({
    alias: 'Levered',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
  })
  isLevered?: SimpleAnswerEnum;

  @meta({
    alias: 'Fund-Level Leverage Limit',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof Fund && self.hasModified('fundLevelLeverageLimit')) {
        if (value === SimpleAnswerEnum.Yes) {
          self.leverageMeta.add('fundLeverage');
        } else {
          self.leverageMeta.fundLeverage = null;
          self.leverageMeta.syncLeverageEntity(self);
        }
      }
    },
  })
  fundLevelLeverageLimit: SimpleAnswerEnum;

  @meta({
    alias: 'Asset-Level Leverage Limit',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof Fund && self.hasModified('assetLevelLeverageLimit')) {
        if (value === SimpleAnswerEnum.Yes) {
          self.leverageMeta.add('assetLeverage');
        } else {
          self.leverageMeta.assetLeverage = null;
          self.leverageMeta.syncLeverageEntity(self);
        }
      }
    },
  })
  assetLevelLeverageLimit: SimpleAnswerEnum;

  @Type(() => Leverage)
  leverage: Array<Leverage>;

  @Transform(() => new LeverageMeta(), { toClassOnly: true })
  @meta({ navigation: true })
  leverageMeta: LeverageMeta = new LeverageMeta();

  //#endregion

  //#region <<< Section - Pacing >>>

  @meta({
    alias: 'Cash Interest',
  })
  cashInterest?: number;

  @Transform(
    ({ value }) => {
      if (Array.isArray(value) && value.length > 0) {
        return value?.join(',');
      }
      return value;
    },
    {
      toClassOnly: true,
    }
  )
  @meta({
    alias: 'Contribution Rates',
  })
  contributionRates?: string;

  @Transform(
    ({ value }) => {
      if (Array.isArray(value) && value.length > 0) {
        return value?.join(',');
      }
      return value;
    },
    {
      toClassOnly: true,
    }
  )
  @meta({
    alias: 'Distribution Rates',
  })
  distributionRates?: string;

  @Transform(
    ({ value }) => {
      if (Array.isArray(value) && value.length > 0) {
        return value?.join(',');
      }
      return value;
    },
    {
      toClassOnly: true,
    }
  )
  @meta({
    alias: 'Growth Rates',
  })
  growthRates?: string;

  //#endregion

  //#region <<< Section - Reporting >>>

  @meta({ alias: 'Avg Reporting - Fund Returns' })
  avgReportingReturns?: number;

  @meta({ alias: 'Avg Reporting - Fund AUM (days)' })
  avgReportingFundAUM?: number;

  @meta({ alias: 'Avg Reporting - Exposures (days)' })
  avgReportingExposures?: number;

  @meta({ alias: 'Avg Reporting - Intra-month returns (days)' })
  avgReportingIntramonthReturns?: number;

  @meta({ alias: 'First Reporting Delay' })
  firstReportingDelay?: boolean;

  @meta({ alias: 'First Reporting Delay Timing (months)' })
  firstReportingDelayTiming?: number;

  @meta({
    alias: 'Fund Return Source',
    source: ReportingSourceEnum.toKeyValue(),
  })
  fundReturnSource?: ReportingSourceEnum;

  @meta({ alias: 'Fund AUM Source', source: ReportingSourceEnum.toKeyValue() })
  fundAUMSource?: ReportingSourceEnum;

  @meta({ alias: 'Log Source' })
  logSource?: string;

  @meta({ alias: 'Reporting Notes' })
  reportingNotes?: string;

  //#endregion

  //#region <<< Section - Identification >>>

  @meta({
    alias: 'CRD',
    isInaudible: true,
  })
  fundCRD?: number;

  @meta({
    alias: 'CIK',
    isInaudible: true,
  })
  fundCIK?: number;

  //#endregion

  @meta({
    alias: 'Commitments (Currency)',
    source: CurrencyEnum.toKeyValue(),
    softNotEmpty: true,
  }) // Target Capital Raise (Base) or Currency
  commitmentCurrency: CurrencyEnum;

  @meta({ alias: 'Program Status', source: ProgramStatusEnum.toKeyValue() })
  programStatus?: ProgramStatusEnum;

  //TODO: Place them to correct files/regions after API is ready
  //#region <<< Section - NEW >>>

  /* @meta({
    alias: 'Fund Status - IN TESTING',
    source: FundStatusEnum.toKeyValue(),
  })
  fundStatus?: FundStatusEnum;*/

  @meta({
    alias: 'Fundraising Status',
    source: FundRaisingStatusEnum.toKeyValue(),
  })
  fundraisingStatus?: FundRaisingStatusEnum;

  @meta({ alias: 'Fundraising Notes' })
  fundraisingNotes?: string;

  @meta({ alias: 'Vintage Year per Manager' })
  vintageYearManager?: number;

  @meta({
    alias: 'Vintage Year per third party',
    /* updates: (self: Meta, value: number) => {
      if (self instanceof Fund) {
        if (!value) {
          self.thirdPartyProvider = undefined;
        }
      }
    }, */
  })
  vintageYearThirdParty?: number;

  //TODO: Remove from Microservices
  @meta({
    alias: 'Third party provider',
    source: ThirdPartyProviderEnum.toKeyValue(),
  })
  thirdPartyProvider?: ThirdPartyProviderEnum;

  /* @meta({ alias: 'Expected Timing - IN TESTING' })
  expectedTiming?: number; */

  @meta({ alias: 'Exclude from Forward Calendar' })
  excludeForwardCalendar?: boolean;

  //#endregion

  closedEndDetails: ClosedEndDetails;

  hybridDetails: HybridDetails;

  @Type(() => CoInvestmentDetails)
  @meta({ navigation: true })
  coInvestmentDetails: CoInvestmentDetails = null;

  @Type(() => SecondaryDetails)
  @meta({ navigation: true })
  secondaryDetails: SecondaryDetails = null;

  @meta({ group: 'className', navigation: true }) //TODO: Check if it breaks anything
  @Transform(
    ({ value }) => {
      value.group = 'className';
      return value;
    },
    { toClassOnly: true }
  )
  shareClasses: Array<OpenShareClass | ClosedShareClass | HybridShareClass>;

  modifiedBy: string;
  managementCompany: ManagementCompany;
  originalValues: string;

  getInceptionDate() {
    const defaultDate = new Date(new Date().getFullYear(), 0, 1);
    return new Date(
      this.commencementOfOperations ?? this.dateOfFormation ?? defaultDate
    );
  }

  auditURL = 'basicdata/fund/audit/{0}/{1}';
  auditURLParams = ['fundId@model', '@prop'];

  constructor() {
    super();
    this.state = ModelState.Ready;
    this.group = 'name';
  }
}
