import { Injectable } from '@angular/core';
import { Type } from 'class-transformer';
import { Observable } from 'rxjs';
import { EntityTypeEnum } from '../enums/enums';
import { AUM } from '../classes/aum/aum.model';
import { IErrorResult } from '../interfaces/system.interface';
import { ApiMicroService } from '@aksia-monorepo/shared-ui';

export class BulkAUM {
  entityId: number;
  entityTypeId: number;
  @Type(() => AUM)
  aum: AUM[];
}

@Injectable({
  providedIn: 'root'
})
export class AumService {
  
  constructor(
    private api: ApiMicroService
  ) { }

  //#region Base CRUD

  public getLatestAUM(entityId, entityTypeId: EntityTypeEnum = EntityTypeEnum.ManagementCompany): Observable<Array<AUM> | AUM>{
    if (entityTypeId == EntityTypeEnum.ManagementCompany){
      return this.api.get(`aum/latestForEntityTree/${entityId}/${EntityTypeEnum.ManagementCompany}/${true}`); 
    }
    else {
      return this.api.get(`aum/latest/${entityId}/${entityTypeId}`);
    }
  }

  public getHistoricalAUM(entityId: number, entityTypeId: EntityTypeEnum): Observable<BulkAUM> {
    return this.api.get(`aum/mergeStream/${entityId}/${entityTypeId}`);
  }

  public updateAUM(o2AUM: Array<AUM>): Observable<Array<AUM | IErrorResult>> {
    //TODO:Data Cleanup - Eliminate all Saved Future AUMs / Eliminate all empty Saved AUMs
    const aumPlain = this.cleanUpAUM(o2AUM).map( aum => aum.toPlain());
    return this.api.post('aum/bulk', aumPlain);
  }

  private cleanUpAUM(o2AUM: Array<AUM>): Array<AUM> {
    const now = new Date();
    o2AUM.forEach( aum => aum.isDeleted = !aum.amount ? true : aum.isDeleted);
    return o2AUM.filter( aum => !(aum.asOfDate.getFullYear() >= now.getFullYear() && aum.asOfDate.getMonth() >= now.getMonth()));
  }

  //#endregion
  
}
