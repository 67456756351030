import { Injectable } from '@angular/core';
import { concat, Observable, of } from 'rxjs';
import { catchError, first, map, toArray } from 'rxjs/operators';
import { classToPlain, plainToClass } from 'class-transformer';
import { AumService, BulkAUM } from './aum.service';
import {
  CarriedInterestRateTypeEnum,
  CloseDateTypeEnum,
  CoInvestPolicyEnum,
  CommitmentsTypeEnum,
  CurrencyEnum,
  DataSourceEnum,
  EligibilityEnum,
  EntityTypeEnum,
  FeeBaseEnum,
  FundStructureEnum,
  LiquidityStructureEnum,
  MinGPCommitmentEnum,
  MonthEnum,
  PeriodTypeEnum,
  PlacementAgentEnum,
  PrefReturnEnum,
  ProgramStatusEnum,
  RateIndexEnum,
  RecyclingEnum,
  SimpleAnswerEnum,
  WaterfallEnum,
} from '../enums/enums';
import { IErrorResult, NoteDTO } from '../interfaces/system.interface';
import { AUM } from '../classes/aum/aum.model';
import {
  ClosedEndDetails,
  ClosedEndGPInformation,
  ClosedEndGPTargetInvestmentStat,
  ClosedEndKeyTerm,
  ClosedEndStructure,
  ClosedEndTargetReturnProfile,
  ClosedShareClass,
  Fund,
  HybridDetails,
  HybridShareClass,
  OpenShareClass,
  Program,
} from '../classes';
import { EntityService } from './entity.service';
import {
  ApiMicroService,
  AuthenticationService,
  LogCategory,
  ModelState,
} from '@aksia-monorepo/shared-ui';
import { LoadingService } from './loading.service';
import { DatePipe } from '@angular/common';
import { NoteService } from './note.service';
import { Worksheet, Comment, CellFormulaValue, stream } from 'exceljs';
import { AUMeta } from '../classes/aum/aum.meta';
import { HttpErrorResponse } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { CoInvestmentDetails } from '../classes/fund/fund.coInvestmentDetails';
import { SecondaryDetails } from '../classes/fund/fund.secondaryDetails';
import { TaxonomyMeta } from '../classes/taxonomy/taxonomy.meta';
import { TaxonomyService } from './taxonomy.service';
import { LeverageMeta } from '../classes/leverage/leverage.meta';
import { LoggingService } from './logging.service';
import {
  IPeriodicStreamMinMax,
  PeriodicStreamsService,
  PeriodicStreamType,
  PERIODIC_STREAMS,
  IPublicReturnStreamPoint,
} from './periodic-streams.service';
import { StreamMeta } from '../classes/stream/stream.meta';
import { Stream } from '../classes/stream/stream.model';
import { MasterFund, SidePocket } from '../classes/entities/entities.model';
import { CompanyService } from './company.service';

export interface IFund {
  new (): Fund;
}

@Injectable({
  providedIn: 'root',
})
export class FundService extends EntityService {
  private datePipe: DatePipe = new DatePipe('en-US');
  public declinedNotes: Array<{ key: string; value: string; desc: string }>;
  public importError = { type: null, message: null };
  public otherClasses: Array<{ openEnd?: unknown; closedEnd?: unknown }>;
  public defaultClass: OpenShareClass | ClosedShareClass | HybridShareClass;
  public selectedClass: OpenShareClass | ClosedShareClass | HybridShareClass;

  constructor(
    private auth: AuthenticationService,
    private api: ApiMicroService,
    private companyService: CompanyService,
    private aumService: AumService,
    private streamService: PeriodicStreamsService,
    private noteService: NoteService,
    protected taxService: TaxonomyService,
    private logService: LoggingService,
    loadingService: LoadingService,
    title: Title
  ) {
    super(loadingService, title, taxService);
  }

  //#region Public Methods
  public getFund(fundId: number) {
    //Reset Params
    this.fund = undefined;
    this.hasError = false;
    this.importError = { type: null, message: null };
    this.noteService._o2Notes.clear();

    this.endPointsResponsed.next(0);
    this.endPointsRequesting = 7;

    this.taxService.loadTaxonomy().subscribe(
      (response: boolean) => {
        if (!response) {
          return;
        }
        this.api.get(`basicdata/fund/details/${fundId}`).subscribe(
          (response: Fund) => {
            if (
              (response.legalStructure === FundStructureEnum.Master_feeder ||
                response.legalStructure === FundStructureEnum.MultipleMasters ||
                response.legalStructure ===
                  FundStructureEnum.Intermediate__Master ||
                response.legalStructure ===
                  FundStructureEnum.UmbrellaMaster_feeder) &&
              response.masterFunds?.length === 0
            ) {
              response.masterFunds = [new MasterFund().toPlain()];
            }

            this.localFund = plainToClass(Fund, response);
            this.localFund.taxonomyMeta = new TaxonomyMeta();
            this.localFund.taxonomyMeta.syncTaxonomyMeta(this.localFund);
            this.localFund.leverageMeta = new LeverageMeta();
            this.localFund.leverageMeta.syncLeverageMeta(this.localFund);
            switch (this.localFund.liquidityStructure) {
              case LiquidityStructureEnum.ClosedEnd:
                this.localFund.closedEndDetails =
                  plainToClass(ClosedEndDetails, response.closedEndDetails) ??
                  new ClosedEndDetails();
                this.localFund.closedEndDetails.parent = this.localFund;
                this.localFund.closedEndDetails.closedEndKeyTerm = response
                  .closedEndDetails?.closedEndKeyTerm
                  ? plainToClass(
                      ClosedEndKeyTerm,
                      response.closedEndDetails.closedEndKeyTerm
                    )
                  : new ClosedEndKeyTerm();
                this.localFund.closedEndDetails.closedEndStructure = response
                  .closedEndDetails?.closedEndStructure
                  ? plainToClass(
                      ClosedEndStructure,
                      response.closedEndDetails.closedEndStructure
                    )
                  : new ClosedEndStructure();
                this.localFund.closedEndDetails.closedEndGPInformation =
                  response.closedEndDetails?.closedEndGPInformation
                    ? plainToClass(
                        ClosedEndGPInformation,
                        response.closedEndDetails.closedEndGPInformation
                      )
                    : new ClosedEndGPInformation();
                this.localFund.closedEndDetails.closedEndGPTargetInvestmentStat =
                  response.closedEndDetails?.closedEndGPTargetInvestmentStat
                    ? plainToClass(
                        ClosedEndGPTargetInvestmentStat,
                        response.closedEndDetails
                          .closedEndGPTargetInvestmentStat
                      )
                    : new ClosedEndGPTargetInvestmentStat();
                this.localFund.closedEndDetails.closedEndTargetReturnProfile =
                  response.closedEndDetails?.closedEndTargetReturnProfile
                    ? plainToClass(
                        ClosedEndTargetReturnProfile,
                        response.closedEndDetails.closedEndTargetReturnProfile
                      )
                    : new ClosedEndTargetReturnProfile();
                this.localFund.coInvestmentDetails =
                  plainToClass(
                    CoInvestmentDetails,
                    response.coInvestmentDetails
                  ) ?? new CoInvestmentDetails(this.localFund.fundId);
                this.localFund.secondaryDetails =
                  plainToClass(SecondaryDetails, response.secondaryDetails) ??
                  new SecondaryDetails(this.localFund.fundId);
                this.localFund.parent = this.localFund;
                this.localFund.hybridDetails = null;
                this.endPointResponsed();
                break;
              case LiquidityStructureEnum.Hybrid:
                this.localFund.hybridDetails = plainToClass(
                  HybridDetails,
                  response.hybridDetails
                );
                this.localFund.closedEndDetails = null;
                break;
              case LiquidityStructureEnum.OpenEnd:
                this.localFund.hybridDetails = null;
                this.localFund.closedEndDetails =
                  plainToClass(ClosedEndDetails, response.closedEndDetails) ??
                  new ClosedEndDetails();
                this.localFund.closedEndDetails.parent = this.localFund;
                this.localFund.closedEndDetails.closedEndKeyTerm = response
                  .closedEndDetails?.closedEndKeyTerm
                  ? plainToClass(
                      ClosedEndKeyTerm,
                      response.closedEndDetails.closedEndKeyTerm
                    )
                  : new ClosedEndKeyTerm();
                this.localFund.closedEndDetails.closedEndTargetReturnProfile =
                  response.closedEndDetails?.closedEndTargetReturnProfile
                    ? plainToClass(
                        ClosedEndTargetReturnProfile,
                        response.closedEndDetails.closedEndTargetReturnProfile
                      )
                    : new ClosedEndTargetReturnProfile();
                this.localFund.coInvestmentDetails =
                  plainToClass(
                    CoInvestmentDetails,
                    response.coInvestmentDetails
                  ) ?? new CoInvestmentDetails(this.localFund.fundId);
                break;
            }

            //Get Investment Program
            this.companyService
              .getInvestmentProgram(this.localFund.investmentProgramId)
              .subscribe(
                (programPlain: Program) => {
                  const program = plainToClass(Program, programPlain);
                  program.sectorId = this.localFund.sectorId;
                  program.strategyId = this.localFund.strategyId;
                  program.substrategyId = this.localFund.substrategyId;
                  program.primaryRegionId = this.localFund.primaryRegionId;
                  this.localFund.investmentProgram = program;
                  this.logService.addInfo(
                    LogCategory.RetrieveData,
                    'LOADED - Investment Program',
                    programPlain
                  );
                  this.endPointResponsed();
                },
                (error) => {
                  this.logService.logException(error);
                  this.endPointResponsed();
                }
              );

            //Get AUM
            this.aumService
              .getHistoricalAUM(fundId, EntityTypeEnum.Fund)
              .subscribe(
                (aumPlain: BulkAUM) => {
                  const aum =
                    aumPlain.aum?.length > 0
                      ? plainToClass(AUM, aumPlain.aum)
                      : [
                          new AUM(
                            this.localFund.fundId,
                            EntityTypeEnum.Fund,
                            new Date()
                          ),
                        ];
                  this.localFund.aumMeta = new AUMeta(
                    this.localFund.fundId,
                    EntityTypeEnum.Fund,
                    this.localFund.getInceptionDate(),
                    this.localFund.name
                  );
                  this.localFund.aumMeta.initAUM(aum);
                  this.localFund.aumMeta.updateSpreadsheet();
                  this.localFund.aumMeta.aumSpreadSheet.hasFullTrackRecord =
                    true;
                  this.localFund.aumMeta.aumSpreadSheet.isVisible = true;
                  this.localFund.aumMeta.aumSpreadSheet.settings.markingAllowed =
                    false;
                  this.localFund.aumMeta.aumSpreadSheet.showHistoryToggle =
                    false;
                  this.localFund.aumMeta.aumSpreadSheet.showCloseToggle = false;

                  this.logService.addInfo(
                    LogCategory.RetrieveData,
                    'LOADED - AUM',
                    aumPlain
                  );
                  this.endPointResponsed();
                },
                (error) => {
                  this.logService.logException(error);
                  this.endPointResponsed();
                }
              );

            //Get Streams
            if (
              this.localFund.liquidityStructure ===
              LiquidityStructureEnum.ClosedEnd
            ) {
              this.streamService
                .getAllStreamMinMaxPoints(this.localFund.fundId)
                .subscribe(
                  (streamPlain: IPeriodicStreamMinMax) => {
                    Object.values(PERIODIC_STREAMS).forEach((streamName) => {
                      const stream =
                        streamPlain && streamPlain[streamName]?.max
                          ? [plainToClass(Stream, streamPlain[streamName].max)]
                          : undefined;
                      this.localFund[`${streamName}Meta`] = new StreamMeta(
                        this.localFund.fundId,
                        EntityTypeEnum.Fund,
                        this.localFund.vintage
                          ? new Date(this.localFund.vintage, 0, 1)
                          : undefined,
                        this.localFund.name,
                        streamName
                      );
                      this.localFund[`${streamName}Meta`].initStream(stream);
                    });
                    this.logService.addInfo(
                      LogCategory.RetrieveData,
                      'LOADED - Streams',
                      streamPlain
                    );
                    this.endPointResponsed();
                  },
                  (error) => {
                    this.logService.logException(error);
                    this.endPointResponsed();
                  }
                );
            } else {
              this.streamService
                .getHistoricalStream(
                  this.localFund.fundId,
                  PERIODIC_STREAMS.PUBLICRETURNS,
                  EntityTypeEnum.Fund
                )
                .subscribe(
                  (streamPlain: unknown) => {
                    let datapoints = (
                      streamPlain as {
                        datapoints: Array<IPublicReturnStreamPoint>;
                      }
                    ).datapoints;

                    let ytdpoints = (
                      streamPlain as {
                        ytd: Array<IPublicReturnStreamPoint>;
                      }
                    ).ytd;

                    datapoints?.forEach((point) => {
                      point.value = point.value * 100;
                      point.isPublic = true;
                    });

                    const stream: Array<Stream> = plainToClass(
                      Stream,
                      datapoints
                    ).sort((a, b) => a.asOf.getTime() - b.asOf.getTime());

                    const ytdStream: Array<Stream> = plainToClass(
                      Stream,
                      ytdpoints
                    );

                    let lastColumnStream: Array<string> = [];

                    /* let years = new Set(
                      stream?.map((point) => point.asOf?.getFullYear())
                    ); */
                    let minYear =
                      this.localFund.commencementOfOperations?.getFullYear();

                    if (minYear) {
                      let thisYear = new Date().getFullYear();
                      let years = new Set(
                        Array.from(
                          { length: thisYear - minYear + 1 },
                          (_, i) => minYear + i
                        )
                      );
                      //https://files.aksia.com/dl/WWY2m9R28z
                      let maxYear = Math.max(...Array.from(years));
                      let additionalYears = new Date().getFullYear() - maxYear;

                      Array.from({ length: additionalYears }).forEach(
                        (_, i) => {
                          years.add(maxYear + i + 1);
                        }
                      );

                      years.forEach((year) => {
                        let ytdPointExists = ytdStream.some(
                          (ytdPoint) => ytdPoint.asOf.getFullYear() === year
                        );
                        if (!ytdPointExists) {
                          ytdStream.push(
                            new Stream(
                              this.localFund.fundId,
                              EntityTypeEnum.Fund,
                              new Date(year, 11, 31)
                            )
                          );
                        }
                      });

                      ytdStream.sort(
                        (a, b) => b.asOf.getTime() - a.asOf.getTime()
                      );
                      lastColumnStream = ytdStream.map((s) =>
                        s?.value !== null && s?.value !== undefined
                          ? s.value * 100 === 0
                            ? '0'
                            : (s.value * 100).toFixed(2)
                          : ''
                      );
                    }

                    this.localFund.publicreturnsMeta = new StreamMeta(
                      this.localFund.fundId,
                      EntityTypeEnum.Fund,
                      this.localFund.commencementOfOperations,
                      this.localFund.name,
                      PERIODIC_STREAMS.PUBLICRETURNS
                    );
                    this.localFund.publicreturnsMeta.initStream(
                      stream,
                      this.localFund.commencementOfOperations
                    );

                    this.localFund.publicreturnsMeta.updateSpreadsheet();

                    this.localFund.publicreturnsMeta.streamSpreadSheet.hasFullTrackRecord =
                      true;
                    this.localFund.publicreturnsMeta.streamSpreadSheet.isVisible =
                      true;
                    this.localFund.publicreturnsMeta.streamSpreadSheet.settings.numberColorFormat =
                      true;
                    this.localFund.publicreturnsMeta.streamSpreadSheet.settings.permitZero =
                      true;
                    this.localFund.publicreturnsMeta.streamSpreadSheet.settings.markingAllowed =
                      false;
                    this.localFund.publicreturnsMeta.streamSpreadSheet.settings.showFormatToggle =
                      false;
                    this.localFund.publicreturnsMeta.streamSpreadSheet.settings.showLastColumn =
                      true;
                    this.localFund.publicreturnsMeta.streamSpreadSheet.settings.lastColumnTitle =
                      'Total';
                    this.localFund.publicreturnsMeta.streamSpreadSheet.settings.lastColumnData =
                      lastColumnStream;
                    this.localFund.publicreturnsMeta.streamSpreadSheet.showHistoryToggle =
                      false;
                    this.localFund.publicreturnsMeta.streamSpreadSheet.showCloseToggle =
                      false;
                    this.localFund.publicreturnsMeta.auditURL = `periodicdata/publicreturns/actual/2@${this.localFund.investmentProgramId}_2/stream/audit?asof={2}&fundId={1}`;
                    this.endPointResponsed();
                  },
                  (error) => {
                    this.logService.logException(error);
                    this.endPointResponsed();
                  }
                );
              this.endPointsRequesting -= 1;
            }

            //Get ShareClasses
            this.api.get(`shareclass/fund/${fundId}`).subscribe(
              (response: Array<any>) => {
                switch (this.localFund.liquidityStructure) {
                  case LiquidityStructureEnum.OpenEnd:
                    const openClasses = response
                      .filter((r) => r.openEnd)
                      .map((classPlain) => {
                        if (
                          classPlain.openEnd?.hasSidePocket ===
                            SimpleAnswerEnum.Yes &&
                          !(classPlain.openEnd?.sidePockets?.length > 0)
                        ) {
                          classPlain.openEnd.sidePockets = [
                            new SidePocket().toPlain(),
                          ];
                        }
                        const openClass = plainToClass(
                          OpenShareClass,
                          classPlain.openEnd
                        );
                        openClass.asOfDate = openClass.source = undefined;
                        return openClass;
                      });
                    if (!openClasses.length) {
                      const openClass = new OpenShareClass(
                        this.localFund.fundId,
                        'Default Class',
                        true
                      );
                      this.localFund.shareClasses = [openClass];
                    } else {
                      this.localFund.shareClasses = openClasses;
                    }

                    this.otherClasses = response.filter((r) => r.closedEnd);
                    break;

                  case LiquidityStructureEnum.ClosedEnd:
                    const closedClasses = response
                      .filter((r) => r.closedEnd)
                      .map((classPlain) => {
                        const closedClass = plainToClass(
                          ClosedShareClass,
                          classPlain.closedEnd
                        );
                        closedClass.asOfDate = closedClass.source = undefined;
                        return closedClass;
                      });
                    if (!closedClasses.length) {
                      const closedClass = new ClosedShareClass(
                        this.localFund.fundId,
                        'Default Class',
                        true
                      );
                      this.localFund.shareClasses = [closedClass];
                    } else {
                      this.localFund.shareClasses = closedClasses;
                    }

                    this.otherClasses = response.filter((r) => r.openEnd);
                    break;

                  case LiquidityStructureEnum.Hybrid:
                    const hybridClasses = response
                      .filter((r) => r.hybrid)
                      .map((classPlain) => {
                        const hybridClass = plainToClass(
                          HybridShareClass,
                          classPlain.hybrid
                        );
                        hybridClass.asOfDate = hybridClass.source = undefined;
                        return hybridClass;
                      });
                    if (!hybridClasses.length) {
                      const hybridClass = new HybridShareClass(
                        this.localFund.fundId,
                        'Default Class',
                        true
                      );
                      this.localFund.shareClasses = [hybridClass];
                    } else {
                      this.localFund.shareClasses = hybridClasses;
                    }
                    break;
                }

                this.defaultClass =
                  this.localFund.shareClasses.find(
                    (sclass) => sclass.isDefault
                  ) ?? this.localFund.shareClasses[0];
                this.selectedClass = this.defaultClass;

                this.logService.addInfo(
                  LogCategory.RetrieveData,
                  'LOADED - Share Classes',
                  response
                );

                this.endPointResponsed();

                //Get Notes
                const noteRequests = [];
                noteRequests.push({
                  entityId: this.localFund.fundId,
                  entityTypeId: EntityTypeEnum.Fund,
                });
                this.localFund.shareClasses
                  .filter((shareclass) => shareclass.classId > -1)
                  .forEach((shareclass) =>
                    noteRequests.push({
                      entityId: shareclass.classId,
                      entityTypeId: EntityTypeEnum.Shareclass,
                    })
                  );
                this.noteService.getNotes(noteRequests).subscribe(
                  (response: Array<NoteDTO>) => {
                    response.forEach((noteDTO) => {
                      if (noteDTO.note) {
                        let noteKV = this.noteService.toMapModel(
                          noteDTO,
                          this.localFund
                        );
                        if (noteKV) {
                          const id =
                            noteKV.noteValue.entityTypeId ===
                            EntityTypeEnum.Shareclass
                              ? this.localFund.shareClasses.find(
                                  (shareclass) =>
                                    shareclass.classId ===
                                    noteKV.noteValue.entityId
                                )?.classId
                              : noteKV.noteValue.entityTypeId ===
                                EntityTypeEnum.Fund
                              ? this.localFund.fundId
                              : this.localCompany.managementCompanyId;
                          if (id) {
                            this.noteService._o2Notes.set(
                              `${noteKV.key}_${noteKV.noteValue.entityTypeId}_${id}`,
                              noteKV.noteValue
                            );
                          }
                        }
                      }
                    });

                    this.logService.addInfo(
                      LogCategory.RetrieveData,
                      'LOADED - Notes',
                      response
                    );

                    this.endPointResponsed();
                  },
                  (error) => {
                    this.logService.logException(error);
                    this.endPointResponsed();
                  }
                );
              },
              (error) => {
                this.logService.logException(error);
                this.endPointResponsed();
              }
            );

            this.logService.addInfo(
              LogCategory.RetrieveData,
              'LOADED - Basic Data',
              response
            );

            this.endPointResponsed();
          },
          (error) => {
            this.logService.logException(error);
            this.endPointResponsed();
          }
        );

        this.logService.addInfo(LogCategory.RetrieveData, 'LOADED - Taxonomy');
      },
      (error) => {
        this.logService.logException(error);
      }
    );

    return this.fund;
  }

  public savePage() {
    this.validateAndPrepare(EntityTypeEnum.Fund);

    if (
      [
        this.fund.state,
        this.fund?.investmentProgram?.state,
        ...this.fund.shareClasses.map((sc) => sc.state),
        this.fund.aumMeta.state,
        ...Object.values(PERIODIC_STREAMS).flatMap((streamName) =>
          this.fund[`${streamName}Meta`]?.stream?.map(
            (streamPoint) => streamPoint.state
          )
        ),
      ].includes(ModelState.HasError)
    ) {
      this.hasError = true;
    }

    const dirtyFund = this.fund.state === ModelState.IsDirty ? 1 : 0;
    const dirtyProgram =
      this.fund.investmentProgram?.state === ModelState.IsDirty ? 1 : 0;
    const dirtyAUM =
      this.fund.aumMeta.aum?.filter(
        (aum) => aum.state === ModelState.IsDirty
      ) ?? [];
    const dirtyPUBLICRETURNS =
      this.fund.publicreturnsMeta?.stream?.filter(
        (streamPoint) => streamPoint.state === ModelState.IsDirty
      ) ?? [];
    const dirtyNETIRR =
      this.fund.netIRRMeta?.stream?.filter(
        (streamPoint) => streamPoint.state === ModelState.IsDirty
      ) ?? [];
    const dirtyGROSSIRR =
      this.fund.grossIRRMeta?.stream?.filter(
        (streamPoint) => streamPoint.state === ModelState.IsDirty
      ) ?? [];
    const dirtyNETMOIC =
      this.fund.netMOICMeta?.stream?.filter(
        (streamPoint) => streamPoint.state === ModelState.IsDirty
      ) ?? [];
    const dirtyGROSSMOIC =
      this.fund.grossMOICMeta?.stream?.filter(
        (streamPoint) => streamPoint.state === ModelState.IsDirty
      ) ?? [];
    const dirtyNETDPI =
      this.fund.netDPIMeta?.stream?.filter(
        (streamPoint) => streamPoint.state === ModelState.IsDirty
      ) ?? [];
    const dirtyNETRVPI =
      this.fund.netRVPIMeta?.stream?.filter(
        (streamPoint) => streamPoint.state === ModelState.IsDirty
      ) ?? [];
    const dirtyINVESTED_CAPITAL =
      this.fund.investedCapitalMeta?.stream?.filter(
        (streamPoint) => streamPoint.state === ModelState.IsDirty
      ) ?? [];
    const dirtyClasses =
      this.fund.shareClasses?.filter((sc) => sc.state === ModelState.IsDirty) ??
      [];
    const dirtyNotes = this.noteService.state === ModelState.IsDirty ? 1 : 0;

    this.endPointsRequesting +=
      dirtyFund +
      dirtyProgram +
      (dirtyAUM?.length > 0 ? 1 : 0) +
      (dirtyPUBLICRETURNS?.length > 0 ? 1 : 0) +
      (dirtyNETIRR?.length > 0 ? 1 : 0) +
      (dirtyGROSSIRR?.length > 0 ? 1 : 0) +
      (dirtyNETMOIC?.length > 0 ? 1 : 0) +
      (dirtyGROSSMOIC?.length > 0 ? 1 : 0) +
      (dirtyNETDPI?.length > 0 ? 1 : 0) +
      (dirtyNETRVPI?.length > 0 ? 1 : 0) +
      (dirtyINVESTED_CAPITAL?.length > 0 ? 1 : 0) +
      (dirtyClasses?.length > 0 ? 1 : 0) +
      dirtyNotes;

    if (dirtyProgram) {
      this.saveProgram(dirtyFund);
    } else if (dirtyFund) {
      this.saveFund();
    }

    if (dirtyClasses?.length > 0) {
      this.saveClasses(dirtyClasses);
    }

    if (
      dirtyAUM?.length > 0 &&
      this.fund.aumMeta.state !== ModelState.HasError
    ) {
      this.saveFundAUM(dirtyAUM);
    }

    if (
      dirtyPUBLICRETURNS?.length > 0 &&
      this.fund.publicreturnsMeta.state !== ModelState.HasError
    ) {
      this.saveStream(dirtyPUBLICRETURNS, PERIODIC_STREAMS.PUBLICRETURNS);
    }

    if (
      dirtyNETIRR?.length > 0 &&
      this.fund.netIRRMeta.state !== ModelState.HasError
    ) {
      this.saveStream(dirtyNETIRR, PERIODIC_STREAMS.NETIRR);
    }

    if (
      dirtyGROSSIRR?.length > 0 &&
      this.fund.grossIRRMeta.state !== ModelState.HasError
    ) {
      this.saveStream(dirtyGROSSIRR, PERIODIC_STREAMS.GROSSIRR);
    }

    if (
      dirtyNETMOIC?.length > 0 &&
      this.fund.netMOICMeta.state !== ModelState.HasError
    ) {
      this.saveStream(dirtyNETMOIC, PERIODIC_STREAMS.NETMOIC);
    }

    if (
      dirtyGROSSMOIC?.length > 0 &&
      this.fund.grossMOICMeta.state !== ModelState.HasError
    ) {
      this.saveStream(dirtyGROSSMOIC, PERIODIC_STREAMS.GROSSMOIC);
    }

    if (
      dirtyNETDPI?.length > 0 &&
      this.fund.netDPIMeta.state !== ModelState.HasError
    ) {
      this.saveStream(dirtyNETDPI, PERIODIC_STREAMS.NETDPI);
    }

    if (
      dirtyNETRVPI?.length > 0 &&
      this.fund.netRVPIMeta.state !== ModelState.HasError
    ) {
      this.saveStream(dirtyNETRVPI, PERIODIC_STREAMS.NETRVPI);
    }

    if (
      dirtyINVESTED_CAPITAL?.length > 0 &&
      this.fund.investedCapitalMeta.state !== ModelState.HasError
    ) {
      this.saveStream(dirtyINVESTED_CAPITAL, PERIODIC_STREAMS.INVESTED_CAPITAL);
    }

    if (dirtyNotes) {
      this.noteService.updateNotes(
        this.fund,
        this.endPointResponsed.bind(this)
      );
    }

    this.logService.logInfo(`Saving - ${this.fund.name}`);
  }

  public saveFund() {
    this.fund.state = ModelState.Saving;
    this.updateFundBasicData(this.localFund)
      .pipe(first())
      .subscribe({
        error: (error) => {
          this.logService.logException(error);
          this.serverError +=
            JSON.stringify(error.error?.Message ?? error) ?? '';
          this.hasError = true;
          this.fund.state = this.localFund.state = ModelState.NotSaved;
          this.endPointResponsed();
        },
        complete: () => {
          this.fund.state = this.localFund.state = ModelState.Saved;
          this.logService.addInfo(
            LogCategory.RetrieveData,
            `SAVED - Basic Data`
          );
          this.defaultClass =
            this.localFund.shareClasses.find((sclass) => sclass.isDefault) ??
            this.localFund.shareClasses[0];
          this.selectedClass = this.localFund.shareClasses.find(
            (sclass) => sclass.classId === this.selectedClass.classId
          );
          this.endPointResponsed();
        },
      });
  }

  public saveProgram(dirtyFund) {
    this.fund.investmentProgram.state = ModelState.Saving;
    this.companyService
      .updateInvestmentProgramData(this.fund.investmentProgram)
      .pipe(first())
      .subscribe({
        error: (error) => {
          this.logService.logException(error);
          this.serverError +=
            JSON.stringify(error.error?.Message ?? error) ?? '';
          this.hasError = true;
          this.fund.investmentProgram.state =
            this.localFund.investmentProgram.state = ModelState.NotSaved;
          this.endPointResponsed();

          if (dirtyFund) {
            this.saveFund();
          }
        },
        complete: () => {
          this.fund.investmentProgram.state =
            this.localFund.investmentProgram.state = ModelState.Saved;
          this.logService.addInfo(
            LogCategory.RetrieveData,
            `SAVED - Investment Program`
          );
          this.endPointResponsed();

          if (dirtyFund) {
            this.saveFund();
          }
        },
      });
  }

  public saveClasses(
    dirtyClasses: Array<OpenShareClass | ClosedShareClass | HybridShareClass>
  ) {
    this.updateClasses(dirtyClasses, ['gloabalLoader', 'saveLoader'])
      .pipe(
        first(),
        map(
          (
            results: Array<
              | OpenShareClass
              | ClosedShareClass
              | HybridShareClass
              | IErrorResult
            >
          ) => {
            results.forEach((res) => {
              if ((res as IErrorResult)?.isError) {
                this.logService.logException(
                  (res as IErrorResult).errorObj.error
                );
                this.serverError +=
                  JSON.stringify((res as IErrorResult).errorObj.error) ?? '';
                this.hasError = true;
                const shareclass = this.localFund.shareClasses.find(
                  (shareclass) =>
                    shareclass.classId == (res as IErrorResult)?.entityId
                );
                const dirtyClass = dirtyClasses.find(
                  (shareclass) =>
                    shareclass.classId == (res as IErrorResult)?.entityId
                );
                if (shareclass) {
                  shareclass.state = dirtyClass.state = ModelState.NotSaved;
                  this.selectedClass = shareclass;
                } else {
                  this.selectedClass = this.localFund.shareClasses[0];
                }
              } else if (res) {
                let savedClass:
                  | OpenShareClass
                  | ClosedShareClass
                  | HybridShareClass = null;
                switch (this.fund.liquidityStructure) {
                  case LiquidityStructureEnum.OpenEnd:
                    let openEnd = res as OpenShareClass;
                    if (
                      openEnd?.hasSidePocket === SimpleAnswerEnum.Yes &&
                      !(openEnd?.sidePockets?.length > 0)
                    ) {
                      openEnd.sidePockets = [new SidePocket().toPlain()];
                    }
                    savedClass = plainToClass(OpenShareClass, res);
                    break;
                  case LiquidityStructureEnum.ClosedEnd:
                    savedClass = plainToClass(ClosedShareClass, res);
                    break;
                  case LiquidityStructureEnum.Hybrid:
                    savedClass = plainToClass(HybridShareClass, res);
                    break;
                }
                savedClass.source = this.fund.dataSource;
                savedClass.asOfDate = this.fund.dataAsOfDate;
                const classIndex = this.localFund.shareClasses.findIndex(
                  (shareclass) => shareclass.className == savedClass.className
                );
                this.localFund.shareClasses[classIndex] = savedClass;
                this.localFund.shareClasses[classIndex].state =
                  ModelState.Saved;
                this.defaultClass =
                  this.localFund.shareClasses.find(
                    (sclass) => sclass.isDefault
                  ) ?? this.localFund.shareClasses[0];
                this.selectedClass = this.defaultClass;
                let updatedClassDTO = res as
                  | OpenShareClass
                  | ClosedShareClass
                  | HybridShareClass;
                this.logService.addInfo(
                  LogCategory.SaveData,
                  `LOADED - Share Class: ${updatedClassDTO?.className} (classId: ${updatedClassDTO?.classId})`
                );
              } else {
                this.logService.addInfo(
                  LogCategory.SaveData,
                  `DELETED - Share Class`
                );
              }
            });
          }
        )
      )
      .subscribe({
        error: (error) => {
          this.logService.logException(error);
          this.serverError += JSON.stringify(error) ?? '';
          this.hasError = true;
          this.endPointResponsed();
        },
        complete: () => {
          this.logService.addInfo(
            LogCategory.SaveData,
            `COMPLETED - All Updates`
          );
          this.endPointResponsed();
        },
      });
  }

  public saveFundAUM(dirtyAUM: Array<AUM>) {
    this.fund.aumMeta.state = ModelState.Saving;
    this.aumService
      .updateAUM(dirtyAUM)
      .pipe(first())
      .subscribe({
        error: (error: HttpErrorResponse) => {
          this.logService.logException(error);
          this.serverError += JSON.stringify(error) ?? '';
          this.hasError = true;
          this.localFund.aumMeta.state = ModelState.NotSaved;
          this.localFund.aumMeta.updateSpreadsheet();
          this.endPointResponsed();
        },
        complete: () => {
          if (this.localFund.aumMeta.state !== ModelState.NotSaved) {
            this.localFund.aumMeta.state = ModelState.Saved;
            this.localFund.aumMeta.aum?.forEach((aum) => {
              if (aum.markedForDeletion) {
                aum.source = undefined;
                aum.markedForDeletion = undefined;
              }
              aum.isNew = false;
              aum.state = ModelState.Ready;
            });
            this.localFund.aumMeta.updateSpreadsheet();
          }
          console.warn('COMPLETED AUM');
          this.logService.addInfo(LogCategory.SaveData, `SAVED - AUM`);
          this.endPointResponsed();
        },
      });
  }

  public saveStream(
    dirtyStream: Array<Stream>,
    streamType: PeriodicStreamType
  ) {
    this.fund[`${streamType}Meta`].state = ModelState.Saving;
    this.streamService
      .updateStream(
        streamType === PERIODIC_STREAMS.PUBLICRETURNS
          ? this.fund.investmentProgramId
          : this.fund.fundId,
        dirtyStream,
        streamType
      )
      .pipe(first())
      .subscribe({
        error: (error: HttpErrorResponse) => {
          console.error(error);
          this.serverError += JSON.stringify(error) ?? '';
          this.hasError = true;
          this.localFund[`${streamType}Meta`].state = ModelState.NotSaved;
          this.localFund.publicreturnsMeta.updateSpreadsheet();
          this.endPointResponsed();
        },
        complete: () => {
          if (
            this.localFund[`${streamType}Meta`].state !== ModelState.NotSaved
          ) {
            this.localFund[`${streamType}Meta`].state = ModelState.Saved;
            if (streamType === PERIODIC_STREAMS.PUBLICRETURNS) {
              this.localFund.publicreturnsMeta.stream?.forEach(
                (streamPoint) => {
                  if (streamPoint.markedForDeletion) {
                    streamPoint.source = undefined;
                    streamPoint.classification = undefined;
                    streamPoint.feeType = undefined;
                    streamPoint.markedForDeletion = undefined;
                  }
                  streamPoint.isNew = false;
                  streamPoint.state = ModelState.Ready;
                }
              );
              this.localFund.publicreturnsMeta.updateSpreadsheet();
            }
          }
          console.warn(`${streamType} Saved!`);
          this.endPointResponsed();
        },
      });
  }

  public importFromExcel(worksheet: Worksheet): void {
    this.importError = { type: null, message: null };
    this.validateAndPrepare();
    this.declinedNotes = [];
    this.endPointsRequesting = 1;

    const firstSection = worksheet.getRow(12).getCell('D').value;
    const nextSection = worksheet.getRow(44).getCell('D').value;
    const shareclass = (this.localFund.shareClasses
      .filter((sclass) => !sclass.markedForDeletion)
      .find((sclass) => sclass.isDefault) ??
      this.localFund.shareClasses.filter(
        (sclass) => !sclass.markedForDeletion
      )[0]) as ClosedShareClass;

    let type: string =
      firstSection === 'Capital Raise'
        ? 'PrivateCredit'
        : nextSection === 'Portfolio Company Statistics (GP Provided)'
        ? this.localFund.type.replace(' ', '') === 'RealAssets'
          ? 'RealAssets'
          : 'PrivateEquity'
        : 'RealEstate';
    let row: number = 13;
    let col: string = 'E';

    if (this.localFund.type.replace(' ', '') === type.replace(' ', '')) {
      //As Of
      this.importCell(
        EntityTypeEnum.Fund,
        worksheet,
        this.localFund,
        'dataAsOfDate',
        10,
        col
      );

      //Source
      this.localFund.dataSource = DataSourceEnum.AksiaTemplate;

      if (this.localFund.type.replace(' ', '') === 'PrivateCredit') {
        //#region Capital Raise

        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'commitmentTarget',
          13,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'targetCapitalRaiseAsOf',
          14,
          col,
          'date'
        );
        if (worksheet.getRow(15).getCell(col).value) {
          this.localFund.closedEndDetails.closedEndKeyTerm.hasCommitmentCap =
            SimpleAnswerEnum.Yes;
        }
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'commitmentCap',
          15,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'commitmentCurrency',
          16,
          col,
          'enum',
          CurrencyEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Shareclass,
          worksheet,
          shareclass,
          'minimumLPCommitment',
          17,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'programStatus',
          18,
          col,
          'enum',
          ProgramStatusEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'firstCloseDate',
          19,
          col,
          'date'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'firstCloseDateType',
          20,
          col,
          'enum',
          CloseDateTypeEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'finalCloseDate',
          21,
          col,
          'date'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'finalCloseDateType',
          22,
          col,
          'enum',
          CloseDateTypeEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'commitmentsActual',
          23,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'commitmentsActualType',
          24,
          col,
          'enum',
          CommitmentsTypeEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'commitmentsAsOf',
          25,
          col,
          'date'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'placementAgent',
          26,
          col,
          'enum',
          PlacementAgentEnum.toKeyValue()
        );

        //#endregion

        //#region Key Terms

        //Investment Periods
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'contractualInvestmentPeriodExpirationYears',
          28,
          col,
          'amount'
        );
        const invPeriodTypes = PeriodTypeEnum.toKeyValue();
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'contractualInvestmentPeriodExpirationType',
          29,
          col,
          'enum',
          invPeriodTypes.map((kv) => {
            return { value: kv.value.replace('Following ', ''), key: kv.key };
          })
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'maxExtensionInvestment',
          30,
          col,
          'amount'
        );

        //Harvest
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'harvestPeriod',
          32,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'maxExtensionHarvest',
          33,
          col,
          'amount'
        );

        //Recycling
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'recyclingOldVersion',
          31,
          col,
          'enum',
          RecyclingEnum.toKeyValue()
        );

        //Subsequent Close Interest
        if (
          worksheet.getRow(43).getCell(col).value ||
          worksheet.getRow(43).getCell(col).note
        ) {
          this.localFund.closedEndDetails.closedEndKeyTerm.subsequentCloseInterest =
            undefined;
          this.localFund.closedEndDetails.closedEndKeyTerm.subsequentCloseInterest =
            SimpleAnswerEnum.Yes;
          this.localFund.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestType =
            RateIndexEnum.FixedRate;
          this.importCell(
            EntityTypeEnum.Fund,
            worksheet,
            this.localFund.closedEndDetails.closedEndKeyTerm,
            'subsequentCloseInterestRate',
            43,
            col,
            'rate'
          );
        }

        //Management Fee
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'managementFee',
          34,
          col,
          'rate'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'managementFeeBase',
          35,
          col,
          'enum',
          FeeBaseEnum.toKeyValue()
        );

        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'managementFeeHarv',
          36,
          col,
          'rate'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'managementFeeBaseHarv',
          37,
          col,
          'enum',
          FeeBaseEnum.toKeyValue()
        );

        //Carried Interest
        if (
          worksheet.getRow(38).getCell(col).value ||
          worksheet.getRow(38).getCell(col).note
        ) {
          shareclass.carriedInterest = undefined;
          shareclass.carriedInterest = SimpleAnswerEnum.Yes;
          shareclass.carriedInterestRateType =
            CarriedInterestRateTypeEnum.SingleRate;
          this.importCell(
            EntityTypeEnum.Shareclass,
            worksheet,
            shareclass,
            'carriedInterestRate',
            38,
            col,
            'rate'
          );

          const prefReturn =
            !!worksheet.getRow(39).getCell(col).value ||
            !!worksheet.getRow(39).getCell(col).note;
          const catchup =
            !!worksheet.getRow(40).getCell(col).value ||
            !!worksheet.getRow(40).getCell(col).note;

          if (prefReturn && catchup) {
            shareclass.carriedInterestPrefReturnType =
              PrefReturnEnum.YesWithCatch_Up;
            this.importCell(
              EntityTypeEnum.Shareclass,
              worksheet,
              shareclass,
              'carriedInterestPrefReturnRate',
              39,
              col,
              'rate'
            );
            this.importCell(
              EntityTypeEnum.Shareclass,
              worksheet,
              shareclass,
              'carriedInterestCatchUpRate',
              40,
              col,
              'rate'
            );
          } else if (prefReturn) {
            shareclass.carriedInterestPrefReturnType =
              PrefReturnEnum.YesWithoutCatch_Up;
            this.importCell(
              EntityTypeEnum.Shareclass,
              worksheet,
              shareclass,
              'carriedInterestPrefReturnRate',
              39,
              col,
              'rate'
            );
          } else {
            shareclass.carriedInterestPrefReturnType = undefined;
          }

          const waterfall =
            !!worksheet.getRow(41).getCell(col).value ||
            !!worksheet.getRow(41).getCell(col).note;
          if (waterfall) {
            const waterFallValue = worksheet.getRow(41).getCell(col).value;
            switch (waterFallValue) {
              case 'European': {
                shareclass.carriedInterestWaterfall = undefined;
                this.importCell(
                  EntityTypeEnum.Shareclass,
                  worksheet,
                  shareclass,
                  'carriedInterestWaterfall',
                  41,
                  col,
                  'enum',
                  WaterfallEnum.toKeyValue()
                );
                break;
              }
              case 'American': {
                shareclass.carriedInterestWaterfall = undefined;
                this.importCell(
                  EntityTypeEnum.Shareclass,
                  worksheet,
                  shareclass,
                  'carriedInterestWaterfall',
                  41,
                  col,
                  'enum',
                  WaterfallEnum.toKeyValue()
                );
                break;
              }
              case 'Modified European': {
                this.importCell(
                  EntityTypeEnum.Shareclass,
                  worksheet,
                  shareclass,
                  'carriedInterestWaterfall',
                  41,
                  col,
                  'enum',
                  WaterfallEnum.toKeyValue()
                );
                shareclass.carriedInterestWaterfall = WaterfallEnum.Other;
                shareclass.carriedInterestWaterfallDesc = waterFallValue;
                break;
              }
            }
          }
        }

        //#endregion

        //#region Discounts

        this.importCell(
          EntityTypeEnum.Shareclass,
          worksheet,
          shareclass,
          'hasFirstCloseDiscount',
          42,
          col,
          'enum',
          SimpleAnswerEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Shareclass,
          worksheet,
          shareclass,
          'hasSizeThresholdDiscount',
          44,
          col,
          'enum',
          SimpleAnswerEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Shareclass,
          worksheet,
          shareclass,
          'hasOtherDiscount',
          45,
          col,
          'enum',
          SimpleAnswerEnum.toKeyValue()
        );

        //#endregion

        //#region Target Return Profile

        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndTargetReturnProfile,
          'gpTargetReturnsGross',
          47,
          col,
          'rate'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndTargetReturnProfile,
          'gpTargetReturnsNet',
          48,
          col,
          'rate'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'capitalCallLine',
          49,
          col,
          'enum',
          SimpleAnswerEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'expectedMaxUsage',
          50,
          col,
          'rate'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'expectedCleanDownDuration',
          51,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndTargetReturnProfile,
          'expectedLeverage',
          52,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndTargetReturnProfile,
          'targetAnnualDivident',
          53,
          col,
          'rate'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndTargetReturnProfile,
          'investmentLevelTransparency',
          54,
          col,
          'enum',
          SimpleAnswerEnum.toKeyValue()
        );

        //#endregion

        //#region Structure

        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndStructure,
          'eligibility',
          56,
          col,
          'enum',
          EligibilityEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndStructure,
          'smAsOffered',
          57,
          col,
          'enum',
          SimpleAnswerEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndStructure,
          'minimumSMACommitment',
          58,
          col,
          'amount'
        );

        const minGPCommitment =
          !!worksheet.getRow(59).getCell(col).value ||
          !!worksheet.getRow(59).getCell(col).note;
        if (minGPCommitment) {
          this.localFund.closedEndDetails.closedEndStructure.minGPCommitmentType =
            undefined;
          this.localFund.closedEndDetails.closedEndStructure.minGPCommitmentType =
            MinGPCommitmentEnum.Percentage;
          this.importCell(
            EntityTypeEnum.Fund,
            worksheet,
            this.localFund.closedEndDetails.closedEndStructure,
            'minGPCommitment',
            59,
            col,
            'rate'
          );
        }
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndStructure,
          'coInvestPolicy',
          60,
          col,
          'enum',
          CoInvestPolicyEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndStructure,
          'lpac',
          61,
          col,
          'enum',
          SimpleAnswerEnum.toKeyValue()
        );

        //#endregion

        //#region GP Information

        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPInformation,
          'wmbe',
          64,
          col,
          'enum',
          SimpleAnswerEnum.toKeyValue()
        );

        //#endregion

        //#region Footnotes

        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'footnotes',
          82,
          col
        );

        //#endregion
      } else if (
        this.localFund.type.replace(' ', '') === 'PrivateEquity' ||
        this.localFund.type.replace(' ', '') === 'RealAssets'
      ) {
        //#region Fund Terms

        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'commitmentTarget',
          13,
          col,
          'amount'
        );
        if (worksheet.getRow(14).getCell(col).value) {
          this.localFund.closedEndDetails.closedEndKeyTerm.hasCommitmentCap =
            SimpleAnswerEnum.Yes;
        }
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'commitmentCap',
          14,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'commitmentCurrency',
          15,
          col,
          'enum',
          CurrencyEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Shareclass,
          worksheet,
          shareclass,
          'minimumLPCommitment',
          16,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'programStatus',
          17,
          col,
          'enum',
          ProgramStatusEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'firstCloseDate',
          18,
          col,
          'date'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'firstCloseDateType',
          19,
          col,
          'enum',
          CloseDateTypeEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'finalCloseDate',
          20,
          col,
          'date'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'finalCloseDateType',
          21,
          col,
          'enum',
          CloseDateTypeEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'commitmentsActual',
          22,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'placementAgent',
          23,
          col,
          'enum',
          PlacementAgentEnum.toKeyValue()
        );

        //#endregion

        //#region Key Terms

        //Investment Periods
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'contractualInvestmentPeriodExpirationYears',
          25,
          col,
          'amount'
        );
        const invPeriodTypes = PeriodTypeEnum.toKeyValue();
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'contractualInvestmentPeriodExpirationType',
          26,
          col,
          'enum',
          invPeriodTypes.map((kv) => {
            return { value: kv.value.replace('Following ', ''), key: kv.key };
          })
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'maxExtensionInvestment',
          27,
          col,
          'amount'
        );

        //Harvest
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'harvestPeriod',
          28,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'maxExtensionHarvest',
          29,
          col,
          'amount'
        );

        //Management Fee
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'managementFee',
          30,
          col,
          'rate'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'managementFeeBase',
          31,
          col,
          'enum',
          FeeBaseEnum.toKeyValue()
        );

        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'managementFeeHarv',
          32,
          col,
          'rate'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'managementFeeBaseHarv',
          33,
          col,
          'enum',
          FeeBaseEnum.toKeyValue()
        );

        //Carried Interest
        if (
          worksheet.getRow(34).getCell(col).value ||
          worksheet.getRow(34).getCell(col).note
        ) {
          shareclass.carriedInterest = undefined;
          shareclass.carriedInterest = SimpleAnswerEnum.Yes;
          shareclass.carriedInterestRateType =
            CarriedInterestRateTypeEnum.SingleRate;
          this.importCell(
            EntityTypeEnum.Shareclass,
            worksheet,
            shareclass,
            'carriedInterestRate',
            34,
            col,
            'rate'
          );

          const prefReturn =
            !!worksheet.getRow(35).getCell(col).value ||
            !!worksheet.getRow(35).getCell(col).note;
          const catchup =
            !!worksheet.getRow(36).getCell(col).value ||
            !!worksheet.getRow(36).getCell(col).note;

          if (prefReturn && catchup) {
            shareclass.carriedInterestPrefReturnType =
              PrefReturnEnum.YesWithCatch_Up;
            this.importCell(
              EntityTypeEnum.Shareclass,
              worksheet,
              shareclass,
              'carriedInterestPrefReturnRate',
              35,
              col,
              'rate'
            );
            this.importCell(
              EntityTypeEnum.Shareclass,
              worksheet,
              shareclass,
              'carriedInterestCatchUpRate',
              36,
              col,
              'rate'
            );
          } else if (prefReturn) {
            shareclass.carriedInterestPrefReturnType =
              PrefReturnEnum.YesWithoutCatch_Up;
            this.importCell(
              EntityTypeEnum.Shareclass,
              worksheet,
              shareclass,
              'carriedInterestPrefReturnRate',
              35,
              col,
              'rate'
            );
          } else {
            shareclass.carriedInterestPrefReturnType = undefined;
          }

          const waterfall =
            !!worksheet.getRow(37).getCell(col).value ||
            !!worksheet.getRow(37).getCell(col).note;
          if (waterfall) {
            const waterFallValue = worksheet.getRow(37).getCell(col).value;
            switch (waterFallValue) {
              case 'European': {
                shareclass.carriedInterestWaterfall = undefined;
                this.importCell(
                  EntityTypeEnum.Shareclass,
                  worksheet,
                  shareclass,
                  'carriedInterestWaterfall',
                  37,
                  col,
                  'enum',
                  WaterfallEnum.toKeyValue()
                );
                break;
              }
              case 'American': {
                shareclass.carriedInterestWaterfall = undefined;
                this.importCell(
                  EntityTypeEnum.Shareclass,
                  worksheet,
                  shareclass,
                  'carriedInterestWaterfall',
                  37,
                  col,
                  'enum',
                  WaterfallEnum.toKeyValue()
                );
                break;
              }
              case 'Modified European': {
                this.importCell(
                  EntityTypeEnum.Shareclass,
                  worksheet,
                  shareclass,
                  'carriedInterestWaterfall',
                  37,
                  col,
                  'enum',
                  WaterfallEnum.toKeyValue()
                );
                shareclass.carriedInterestWaterfall = WaterfallEnum.Other;
                shareclass.carriedInterestWaterfallDesc = waterFallValue;
                break;
              }
            }
          }
        }

        //#endregion

        //#region Target Return Profile

        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndTargetReturnProfile,
          'gpTargetReturnsGross',
          39,
          col,
          'rate'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndTargetReturnProfile,
          'gpTargetReturnsNet',
          40,
          col,
          'rate'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'capitalCallLine',
          41,
          col,
          'enum',
          SimpleAnswerEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'expectedMaxUsage',
          42,
          col,
          'rate'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'expectedCleanDownDuration',
          43,
          col,
          'amount'
        );

        //#endregion

        //#region Portfolio Company Statistics (GP Provided)

        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPTargetInvestmentStat,
          'revenueRangeLow',
          45,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPTargetInvestmentStat,
          'revenueRangeHigh',
          46,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPTargetInvestmentStat,
          'ebitdaRangeLow',
          47,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPTargetInvestmentStat,
          'ebitdaRangeHigh',
          48,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPTargetInvestmentStat,
          'enterpriseValueRangeLow',
          49,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPTargetInvestmentStat,
          'enterpriseValueRangeHigh',
          50,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPTargetInvestmentStat,
          'equityInvestmentRangeLow',
          51,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPTargetInvestmentStat,
          'equityInvestmentRangeHigh',
          52,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPTargetInvestmentStat,
          'leverageRangeLow',
          53,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPTargetInvestmentStat,
          'leverageRangeHigh',
          54,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPTargetInvestmentStat,
          'transactionMultipleLow',
          55,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPTargetInvestmentStat,
          'transactionMultipleHigh',
          56,
          col,
          'amount'
        );

        //#endregion

        //#region Structure

        const minGPCommitment =
          !!worksheet.getRow(58).getCell(col).value ||
          !!worksheet.getRow(58).getCell(col).note;
        if (minGPCommitment) {
          this.localFund.closedEndDetails.closedEndStructure.minGPCommitmentType =
            undefined;
          this.localFund.closedEndDetails.closedEndStructure.minGPCommitmentType =
            MinGPCommitmentEnum.Percentage;
          this.importCell(
            EntityTypeEnum.Fund,
            worksheet,
            this.localFund.closedEndDetails.closedEndStructure,
            'minGPCommitment',
            58,
            col,
            'rate'
          );
        }
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndStructure,
          'coInvestPolicy',
          59,
          col,
          'enum',
          CoInvestPolicyEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPInformation,
          'thirdPartyManagement',
          60,
          col,
          'enum',
          SimpleAnswerEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'agmTiming',
          61,
          col,
          'enum',
          MonthEnum.toKeyValue()
        );

        //#endregion

        //#region GP Information

        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPInformation,
          'wmbe',
          64,
          col,
          'enum',
          SimpleAnswerEnum.toKeyValue()
        );

        //#endregion

        //#region Footnotes

        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'footnotes',
          82,
          col
        );

        //#endregion
      } else if (this.localFund.type.replace(' ', '') === 'RealEstate') {
        //#region Fund Terms

        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'commitmentTarget',
          13,
          col,
          'amount'
        );
        if (worksheet.getRow(14).getCell(col).value) {
          this.localFund.closedEndDetails.closedEndKeyTerm.hasCommitmentCap =
            SimpleAnswerEnum.Yes;
        }
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'commitmentCap',
          14,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'commitmentCurrency',
          15,
          col,
          'enum',
          CurrencyEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Shareclass,
          worksheet,
          shareclass,
          'minimumLPCommitment',
          16,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'programStatus',
          17,
          col,
          'enum',
          ProgramStatusEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'firstCloseDate',
          18,
          col,
          'date'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'firstCloseDateType',
          19,
          col,
          'enum',
          CloseDateTypeEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'finalCloseDate',
          20,
          col,
          'date'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'finalCloseDateType',
          21,
          col,
          'enum',
          CloseDateTypeEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'commitmentsActual',
          22,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'placementAgent',
          23,
          col,
          'enum',
          PlacementAgentEnum.toKeyValue()
        );

        //#endregion

        //#region Key Terms

        //Investment Periods
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'contractualInvestmentPeriodExpirationYears',
          25,
          col,
          'amount'
        );
        const invPeriodTypes = PeriodTypeEnum.toKeyValue();
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'contractualInvestmentPeriodExpirationType',
          26,
          col,
          'enum',
          invPeriodTypes.map((kv) => {
            return { value: kv.value.replace('Following ', ''), key: kv.key };
          })
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'maxExtensionInvestment',
          27,
          col,
          'amount'
        );

        //Harvest
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'harvestPeriod',
          28,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'maxExtensionHarvest',
          29,
          col,
          'amount'
        );

        //Management Fee
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'managementFee',
          30,
          col,
          'rate'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'managementFeeBase',
          31,
          col,
          'enum',
          FeeBaseEnum.toKeyValue()
        );

        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'managementFeeHarv',
          32,
          col,
          'rate'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'managementFeeBaseHarv',
          33,
          col,
          'enum',
          FeeBaseEnum.toKeyValue()
        );

        //Carried Interest
        if (
          worksheet.getRow(34).getCell(col).value ||
          worksheet.getRow(34).getCell(col).note
        ) {
          shareclass.carriedInterest = undefined;
          shareclass.carriedInterest = SimpleAnswerEnum.Yes;
          shareclass.carriedInterestRateType =
            CarriedInterestRateTypeEnum.SingleRate;
          this.importCell(
            EntityTypeEnum.Shareclass,
            worksheet,
            shareclass,
            'carriedInterestRate',
            34,
            col,
            'rate'
          );

          const prefReturn =
            !!worksheet.getRow(35).getCell(col).value ||
            !!worksheet.getRow(35).getCell(col).note;
          const catchup =
            !!worksheet.getRow(36).getCell(col).value ||
            !!worksheet.getRow(36).getCell(col).note;

          if (prefReturn && catchup) {
            shareclass.carriedInterestPrefReturnType =
              PrefReturnEnum.YesWithCatch_Up;
            this.importCell(
              EntityTypeEnum.Shareclass,
              worksheet,
              shareclass,
              'carriedInterestPrefReturnRate',
              35,
              col,
              'rate'
            );
            this.importCell(
              EntityTypeEnum.Shareclass,
              worksheet,
              shareclass,
              'carriedInterestCatchUpRate',
              36,
              col,
              'rate'
            );
          } else if (prefReturn) {
            shareclass.carriedInterestPrefReturnType =
              PrefReturnEnum.YesWithoutCatch_Up;
            this.importCell(
              EntityTypeEnum.Shareclass,
              worksheet,
              shareclass,
              'carriedInterestPrefReturnRate',
              35,
              col,
              'rate'
            );
          } else {
            shareclass.carriedInterestPrefReturnType = undefined;
          }

          const waterfall =
            !!worksheet.getRow(37).getCell(col).value ||
            !!worksheet.getRow(37).getCell(col).note;
          if (waterfall) {
            const waterFallValue = worksheet.getRow(37).getCell(col).value;
            switch (waterFallValue) {
              case 'European': {
                shareclass.carriedInterestWaterfall = undefined;
                this.importCell(
                  EntityTypeEnum.Shareclass,
                  worksheet,
                  shareclass,
                  'carriedInterestWaterfall',
                  37,
                  col,
                  'enum',
                  WaterfallEnum.toKeyValue()
                );
                break;
              }
              case 'American': {
                shareclass.carriedInterestWaterfall = undefined;
                this.importCell(
                  EntityTypeEnum.Shareclass,
                  worksheet,
                  shareclass,
                  'carriedInterestWaterfall',
                  37,
                  col,
                  'enum',
                  WaterfallEnum.toKeyValue()
                );
                break;
              }
              case 'Modified European': {
                this.importCell(
                  EntityTypeEnum.Shareclass,
                  worksheet,
                  shareclass,
                  'carriedInterestWaterfall',
                  37,
                  col,
                  'enum',
                  WaterfallEnum.toKeyValue()
                );
                shareclass.carriedInterestWaterfall = WaterfallEnum.Other;
                shareclass.carriedInterestWaterfallDesc = waterFallValue;
                break;
              }
            }
          }
        }

        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'multipleTierHurdle',
          38,
          col,
          'amount'
        );

        //#endregion

        //#region Target Return Profile

        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndTargetReturnProfile,
          'gpTargetReturnsGross',
          40,
          col,
          'rate'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndTargetReturnProfile,
          'gpTargetReturnsNet',
          41,
          col,
          'rate'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'capitalCallLine',
          44,
          col,
          'enum',
          SimpleAnswerEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'expectedMaxUsage',
          45,
          col,
          'rate'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'expectedCleanDownDuration',
          46,
          col,
          'amount'
        );

        //#endregion

        //#region Investment Details

        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPTargetInvestmentStat,
          'seedAssets',
          48,
          col,
          'enum',
          SimpleAnswerEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPTargetInvestmentStat,
          'programEquityCommittedSeedAssets',
          49,
          col,
          'amount'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPTargetInvestmentStat,
          'targetOverallLTV',
          50,
          col,
          'rate'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPTargetInvestmentStat,
          'maxLTVPortfolio',
          51,
          col,
          'rate'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPTargetInvestmentStat,
          'maxLTVIndividualAsset',
          52,
          col,
          'rate'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPTargetInvestmentStat,
          'maxDevelopment',
          53,
          col,
          'rate'
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPTargetInvestmentStat,
          'targetHoldingPeriod',
          54,
          col,
          'amount'
        );

        //#endregion

        //#region Structure

        const minGPCommitment =
          !!worksheet.getRow(56).getCell(col).value ||
          !!worksheet.getRow(56).getCell(col).note;
        if (minGPCommitment) {
          this.localFund.closedEndDetails.closedEndStructure.minGPCommitmentType =
            undefined;
          this.localFund.closedEndDetails.closedEndStructure.minGPCommitmentType =
            MinGPCommitmentEnum.Percentage;
          this.importCell(
            EntityTypeEnum.Fund,
            worksheet,
            this.localFund.closedEndDetails.closedEndStructure,
            'minGPCommitment',
            56,
            col,
            'rate'
          );
        }
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndStructure,
          'coInvestPolicy',
          57,
          col,
          'enum',
          CoInvestPolicyEnum.toKeyValue()
        );
        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPInformation,
          'thirdPartyManagement',
          58,
          col,
          'enum',
          SimpleAnswerEnum.toKeyValue()
        );

        //#endregion

        //#region GP Information

        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndGPInformation,
          'wmbe',
          64,
          col,
          'enum',
          SimpleAnswerEnum.toKeyValue()
        );

        //#endregion

        //#region Footnotes

        this.importCell(
          EntityTypeEnum.Fund,
          worksheet,
          this.localFund.closedEndDetails.closedEndKeyTerm,
          'footnotes',
          82,
          col
        );

        //#endregion
      }
    } else {
      this.importError.type = 'HasErrors';
      this.importError.message = `Type Mismatch: ${this.localFund.name} is of type ${this.localFund.type} while the excel file is of type ${type}`;
    }
    if (this.declinedNotes?.length > 0) {
      const notesInfo = this.declinedNotes
        .map((dn) => `${dn.key} ${dn.value} \n${dn.desc}`)
        .join('\n\n');
      this.importError.type = 'HasWarnings';
      this.importError.message = `The following data and/or notes could not be imported:\n\r ${notesInfo}`;
    }
    this.endPointResponsed();
    if (this.importError.type !== 'HasErrors') {
      this.fund.state = ModelState.IsDirty;
      shareclass.state = ModelState.IsDirty;
      this.noteService.state = ModelState.IsDirty;
    }

    //return this.importErrorMessage;
  }

  private importCell(
    entityType: EntityTypeEnum,
    worksheet: Worksheet,
    prop: unknown,
    propName: string,
    rowNum: number,
    cellNum: string,
    dataType: 'enum' | 'date' | 'rate' | 'amount' = null,
    enumKeyValue: Array<{ value: string; key: any }> = null
  ) {
    const shareclass = (this.localFund.shareClasses
      .filter((sclass) => !sclass.markedForDeletion)
      .find((sclass) => sclass.isDefault) ??
      this.localFund.shareClasses.filter(
        (sclass) => !sclass.markedForDeletion
      )[0]) as ClosedShareClass;
    const entityId =
      entityType === EntityTypeEnum.Shareclass
        ? shareclass.classId
        : this.localFund.fundId;
    const cell = worksheet.getRow(rowNum).getCell(cellNum);
    const importedValue = cell?.formula
      ? (cell?.value as CellFormulaValue)?.result
      : cell?.value;
    const importedNoteOrComment = cell?.note;

    if (importedValue || importedNoteOrComment) {
      //Value import
      if (importedValue !== undefined && importedValue !== null) {
        switch (dataType) {
          case 'date': {
            let importedDate = new Date(importedValue as string);
            if (!isNaN(importedDate?.getTime())) {
              prop[propName] = importedDate;
              this.checkFutureDate(importedDate, prop, propName);
            } else {
              const fieldName = worksheet.getRow(rowNum).getCell('D')
                ?.value as string;
              this.declinedNotes.push({
                key: fieldName,
                value: `${importedDate}`,
                desc: ' - Check if cell value is a date of proper format (ex.: 3/31/2021) instead of a formula or other text.',
              });
            }
            break;
          }
          case 'rate': {
            if (!isNaN(importedValue as number)) {
              prop[propName] = (importedValue as number) * 100;
            } else {
              const fieldName = worksheet.getRow(rowNum).getCell('D')
                ?.value as string;
              this.declinedNotes.push({
                key: fieldName,
                value: `${importedValue}`,
                desc: ' - Check if cell value is numeric (ex.: 50% or 9.00) instead of a formula or other text.',
              });
            }

            break;
          }
          case 'amount': {
            if (!isNaN(importedValue as number)) {
              prop[propName] = importedValue;
            } else {
              const fieldName = worksheet.getRow(rowNum).getCell('D')
                ?.value as string;
              this.declinedNotes.push({
                key: fieldName,
                value: `${importedValue}`,
                desc: ' - Check if cell value is numeric (ex.: $10,000,000 or 1.60) instead of a formula or other text.',
              });
            }

            break;
          }
          case 'enum': {
            let enumFound = enumKeyValue.find(
              (kv) =>
                kv.value ===
                (worksheet.getRow(rowNum).getCell(cellNum).value as string)
            );
            if (!!enumFound) {
              prop[propName] = enumFound.key;
            } else {
              const fieldName = worksheet.getRow(rowNum).getCell('D')
                ?.value as string;
              this.declinedNotes.push({
                key: fieldName,
                value: `${worksheet.getRow(rowNum).getCell(cellNum).value}`,
                desc: ` - Check if cell value is among: ${enumKeyValue
                  .map((kv) => kv.value)
                  .join(', ')}`,
              });
            }
            break;
          }
          default:
            prop[propName] = importedValue;
        }
      }

      //Note import
      if (importedNoteOrComment) {
        const noteText =
          typeof importedNoteOrComment === 'string'
            ? importedNoteOrComment
            : (importedNoteOrComment as Comment).texts[0]?.text;

        if (
          (!shareclass.classId || shareclass.classId === -1) &&
          entityType === EntityTypeEnum.Shareclass
        ) {
          const fieldName = worksheet.getRow(rowNum).getCell('D')
            ?.value as string;
          this.declinedNotes.push({
            key: `(Note) ${fieldName}`,
            value: noteText,
            desc: ' - Could not be imported due to unsaved Shareclass',
          });
          return;
        }

        let note = this.noteService._o2Notes.get(
          `${propName}_${entityType}_${entityId}`
        );
        if (note) {
          note.note = noteText;
          note._isDirty = true;
          this.noteService._o2Notes.set(
            `${propName}_${entityType}_${entityId}`,
            note
          );
        } else {
          const newNote: NoteDTO = {
            entityId: entityId,
            entityTypeId: entityType,
            fieldName: this.noteService.formatFieldName(propName, true),
            modifiedBy: this.auth.user.username,
            modifiedOn: new Date().toISOString(),
            note: noteText,
            system: 'O2',
          };
          const noteKV = this.noteService.toMapModel(newNote, this.localFund);
          noteKV.noteValue._isDirty = true;
          this.noteService._o2Notes.set(
            `${propName}_${entityType}_${entityId}`,
            noteKV.noteValue
          );
        }
      }
    }
  }

  private checkFutureDate(date: Date, prop: unknown, propName: string) {
    console.log(prop);
    /* if (date) {
      console.log(prop);
    } */
  }

  //#endregion

  //#region Private Methods

  private updateFundBasicData(o2Fund: Fund): Observable<unknown> {
    const o2FundDTO = o2Fund.toPlain();
    delete o2FundDTO.auditURL;
    delete o2FundDTO.auditURLParams;
    delete o2FundDTO.classType;
    delete o2FundDTO.group;
    delete o2FundDTO.investmentProgram;
    delete o2FundDTO.shareClasses;
    delete o2FundDTO.taxonomyMeta;
    delete o2FundDTO.leverageMeta;
    delete o2FundDTO.publicreturnsMeta;
    delete o2FundDTO.aumMeta;
    delete o2FundDTO.netIRRMeta;
    delete o2FundDTO.grossIRRMeta;
    delete o2FundDTO.grossMOICMeta;
    delete o2FundDTO.netMOICMeta;
    delete o2FundDTO.netDPIMeta;
    delete o2FundDTO.netRVPIMeta;
    delete o2FundDTO.investedCapitalMeta;

    //TODO: Refactor this to be more generic
    o2FundDTO.masterFunds = o2FundDTO.masterFunds?.filter((mf) => mf.name);

    o2FundDTO.contributionRates = o2FundDTO.contributionRates
      ?.split(',')
      ?.filter((rate) => rate !== '')
      ?.map((rate) => +rate);

    o2FundDTO.distributionRates = o2FundDTO.distributionRates
      ?.split(',')
      ?.filter((rate) => rate !== '')
      ?.map((rate) => +rate);

    o2FundDTO.growthRates = o2FundDTO.growthRates
      ?.split(',')
      ?.filter((rate) => rate !== '')
      ?.map((rate) => +rate);

    this.logService.addInfo(
      LogCategory.SaveData,
      'Saving Basic Data',
      o2FundDTO
    );
    return this.api.put(`basicdata/fund`, o2FundDTO);
  }

  private updateClasses(
    shareClasses: Array<OpenShareClass | ClosedShareClass | HybridShareClass>,
    loaderIds: string[]
  ): Observable<Array<OpenShareClass | ClosedShareClass | IErrorResult>> {
    const updates$: Array<
      Observable<OpenShareClass | ClosedShareClass | IErrorResult>
    > = [];

    shareClasses
      .sort((a, b) => (a.isDefault ? 1 : -1))
      .forEach((shareclass) => {
        shareclass.state = ModelState.Saving;
        if (!shareclass.isDeleted && shareclass.classId) {
          updates$.push(this.updateClass(shareclass, loaderIds));
        } else if (!shareclass.isDeleted && !shareclass.classId) {
          updates$.push(this.addClass(shareclass, loaderIds));
        } else if (shareclass.isDeleted && shareclass.classId) {
          updates$.push(this.deleteClass(shareclass, loaderIds));
        }
      });

    return concat(...updates$).pipe(toArray());
  }

  private addClass(
    shareClass: OpenShareClass | ClosedShareClass | HybridShareClass,
    loaderIds: string[]
  ): Observable<OpenShareClass | ClosedShareClass | IErrorResult> {
    const apiUrl = `shareclass/${
      shareClass instanceof OpenShareClass ? 'openend' : 'closedend'
    }`;
    const shareClassDTO = shareClass.toPlain();
    if (shareClassDTO.sidePockets?.length > 0) {
      shareClassDTO.sidePockets = shareClassDTO.sidePockets.filter(
        (sp) => sp.sidePocketLevel || sp.sidePocketLimit
      );
    }
    this.logService.addInfo(
      LogCategory.SaveData,
      'Adding Share Class',
      shareClassDTO
    );
    return this.api.post(apiUrl, shareClassDTO).pipe(
      catchError((err) => {
        return of(<IErrorResult>{
          errorObj: err,
          isError: true,
          entityId: shareClass.classId,
        });
      })
    );
  }

  private updateClass(
    shareClass: OpenShareClass | ClosedShareClass | HybridShareClass,
    loaderIds: string[]
  ): Observable<OpenShareClass | ClosedShareClass | IErrorResult> {
    const apiUrl = `shareclass/${
      shareClass instanceof OpenShareClass ? 'openend' : 'closedend'
    }`;
    const shareClassDTO = shareClass.toPlain();
    if (shareClassDTO.sidePockets?.length > 0) {
      shareClassDTO.sidePockets = shareClassDTO.sidePockets.filter(
        (sp) => sp.sidePocketLevel || sp.sidePocketLimit
      );
    }
    this.logService.addInfo(
      LogCategory.SaveData,
      'Updating Share Class',
      shareClassDTO
    );
    return this.api.put(apiUrl, shareClassDTO).pipe(
      catchError((err) => {
        return of(<IErrorResult>{
          errorObj: err,
          isError: true,
          entityId: shareClass.classId,
        });
      })
    );
  }

  private deleteClass(
    shareClass: OpenShareClass | ClosedShareClass | HybridShareClass,
    loaderIds: string[]
  ): Observable<OpenShareClass | ClosedShareClass | IErrorResult> {
    const apiUrl = `shareclass/${shareClass.classId}`;
    this.logService.addInfo(
      LogCategory.SaveData,
      'Deleting Share Class',
      shareClass.classId
    );
    return this.api.delete(apiUrl).pipe(
      catchError((err) => {
        return of(<IErrorResult>{
          errorObj: err,
          isError: true,
          entityId: shareClass.classId,
        });
      })
    );
  }

  //#endregion
}
