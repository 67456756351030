import { meta, Meta, ModelState } from '@aksia-monorepo/shared-ui';
import { Type } from 'class-transformer';
import {
  EntityTypeEnum,
  PublicReturnSourceEnum,
  StreamSourceEnum,
} from '../../enums/enums';
import { PeriodicStreamType } from '../../services/periodic-streams.service';
import { ManagementCompany, Program } from '../company/company.model';
import { Fund } from '../fund/fund.model';
import { Stream } from './stream.model';
import { StreamSpreadSheet } from './stream.spreadsheet';

export class StreamMeta extends Meta {
  classType = 'StreamMeta';
  entityId: number;
  entityTypeId?: EntityTypeEnum;
  entityName: string;
  streamType: PeriodicStreamType;
  __group = 'entityName';

  @Type(() => Date)
  streamStartDate: Date;

  streamSpreadSheet: StreamSpreadSheet;

  @Type(() => Stream)
  selectedStream: Stream;

  @meta({ historyLength: 3 })
  selectedCurrency: string;

  @Type(() => Date)
  selectedAsOf: Date;

  selectedSource: StreamSourceEnum | PublicReturnSourceEnum;

  @Type(() => Stream)
  @meta({ alias: '(As Of [asOf])', navigation: true })
  stream: Array<Stream>;

  columnTitles = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  auditURL = `periodicdata/{0}/{1}/stream/audit?asOf={2}`;

  set asof(value: Date) {
    StreamMeta.setAsOf(this, value);
  }

  set source(value: StreamSourceEnum) {
    this.selectedSource = value;
    StreamMeta.setSource(this, value);
  }

  toggleSpreadSheet(
    entity: ManagementCompany | Fund | Program,
    streamProp: string
  ) {
    StreamMeta.toggleSpreadSheet(entity, streamProp);
  }

  toggleSpreadSheetHistory() {
    return StreamMeta.toggleSpreadSheetHistory(this);
  }

  static toggleSpreadSheetHistory(self: StreamMeta) {
    self.streamSpreadSheet.showHistory = !self.streamSpreadSheet.showHistory;
  }

  static toggleSpreadSheet(
    entity: ManagementCompany | Fund | Program,
    streamProp: string
  ) {
    entity[`${streamProp}Meta`].streamSpreadSheet.isVisible =
      !entity[`${streamProp}Meta`].streamSpreadSheet.isVisible;

    if (!entity[`${streamProp}Meta`].streamSpreadSheet.isVisible) {
      entity[`${streamProp}Meta`].streamSpreadSheet.showHistory = false;
    }
  }

  static setAsOf(self: StreamMeta, asOf: Date) {
    let stream = self.stream?.find(
      (aum) =>
        aum?.asOf?.getFullYear() === asOf.getFullYear() &&
        aum?.asOf?.getMonth() === asOf.getMonth()
    );
    if (!stream) {
      stream = new Stream(self.entityId, self.entityTypeId, asOf);
      self.stream = [...(self.stream ?? []), stream];
    }
    self.selectedStream = stream;
    self.selectedAsOf = asOf;
    self.selectedSource = self.selectedStream?.source ?? null;
  }

  static setSource(self: StreamMeta, source: StreamSourceEnum) {
    self.selectedSource = source;
    let minYear, maxYear, minMonth, maxMonth;
    if (self.streamSpreadSheet?.isVisible) {
      minYear = maxYear =
        +self.streamSpreadSheet?.settings.rowTitles[
          self.streamSpreadSheet?.settings.selectedCell.row
        ];
      minMonth = maxMonth = self.streamSpreadSheet?.settings.selectedCell.col;

      if (self.streamSpreadSheet?.settings.markedCells) {
        for (const [key, value] of Object.entries(
          self.streamSpreadSheet.settings.markedCells
        )) {
          if (value > -1) {
            maxYear =
              +self.streamSpreadSheet?.settings.rowTitles[
                self.streamSpreadSheet?.settings.markedCells.startRow
              ];
            minYear =
              +self.streamSpreadSheet?.settings.rowTitles[
                self.streamSpreadSheet?.settings.markedCells.endRow
              ];
            minMonth = self.streamSpreadSheet?.settings.markedCells.startCol;
            maxMonth = self.streamSpreadSheet?.settings.markedCells.endCol;
            break;
          }
        }
      }
    } else {
      minYear = maxYear = self.selectedAsOf.getFullYear();
      minMonth = maxMonth = self.selectedAsOf.getMonth();
    }

    self.stream?.forEach((streamPoint) => {
      if (
        streamPoint.asOf.getFullYear() <= maxYear &&
        streamPoint.asOf.getFullYear() >= minYear &&
        streamPoint.asOf.getMonth() <= maxMonth &&
        streamPoint.asOf.getMonth() >= minMonth &&
        streamPoint.value
      ) {
        streamPoint.source = source;
        streamPoint.state = streamPoint.source
          ? ModelState.IsDirty
          : ModelState.HasError;
        self.state = streamPoint.source
          ? ModelState.IsDirty
          : ModelState.HasError;
      }
    });
  }

  initStream(stream: Array<Stream>, asof: Date) {
    if (stream?.length > 0 && this.streamStartDate) {
      this.stream = stream;
      this.selectedStream = asof
        ? this.stream.find(
            (point) =>
              point.asOf.getFullYear() === asof.getFullYear() &&
              point.asOf.getMonth() === asof.getMonth()
          )
        : this.stream[this.stream.length - 1];
      if (this.selectedStream) {
        this.selectedAsOf = this.selectedStream.asOf;
        this.selectedSource = this.selectedStream.source;
      }
    }
  }

  updateStream(streamPoint: Stream) {
    if (
      streamPoint &&
      this.streamStartDate &&
      !this.stream?.find(
        (point) =>
          point.asOf.getFullYear() === streamPoint.asOf.getFullYear() &&
          point.asOf.getMonth() === streamPoint.asOf.getMonth()
      )
    ) {
      this.stream = [...(this.stream ?? []), streamPoint];
      this.selectedStream = streamPoint;
      this.selectedAsOf = this.selectedStream.asOf;
      this.selectedSource = this.selectedStream.source;
    }
  }

  updateSpreadsheet() {
    if (!this.streamSpreadSheet) {
      this.streamSpreadSheet = new StreamSpreadSheet(
        this.entityId,
        this.entityTypeId,
        this.streamStartDate,
        this.columnTitles,
        [new Date().getFullYear().toString()],
        Array.from(Array(12), (_, i) => new Date().getMonth() < i)
      );
    } else {
      this.streamSpreadSheet.entityStartDate = this.streamStartDate;
    }

    if (this.streamStartDate) {
      this.streamSpreadSheet.streamToSpreadsheet(this.stream);
    }
  }

  constructor(
    entityId: number,
    entityTypeId: EntityTypeEnum,
    startDate: Date,
    entityName: string,
    streamType: PeriodicStreamType
  ) {
    super();
    this.entityId = entityId;
    this.entityTypeId = entityTypeId;
    this.entityName = entityName;

    //vintage = min(Drawdown Due Date, Accrual Date) || Final Close Date || Inception Date = (Commencement of Operation || Date of Establishment)
    this.streamStartDate = startDate;
    this.state = ModelState.Ready;
    this.streamType = streamType;
    this.classType = `${streamType}Meta`;
  }
}
